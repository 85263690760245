<template>
  <v-container v-if="checkedTabOnLoad">
    <!-- Tab Options -->
    <v-row>
      <v-col
        cols="12"
        class="ma-0 mb-3 py-0"
      >
        <v-card class="my-0">
          <Crumbs v-if="isMediaPlanner || allowCreator" :breadcrumbItems='breadcrumbItems'/>
          <v-tabs v-model="tab">
            <v-tab v-show="$store.state.Permissions.creatorEditor && allowCreator()">
              Creator
            </v-tab>
            <v-tab v-show="$store.state.Permissions.uploadMediaView">
              Upload Media
            </v-tab>
            <v-tab v-show="$store.state.Permissions.locationView">
              Locations
            </v-tab>
            <v-tab v-show="$store.state.Permissions.airportView && burstDeliveryType === 'dynamic'">
              Airport
            </v-tab>
            <v-tab v-show="$store.state.Permissions.previewView">
              Preview
            </v-tab>
            <v-tab v-show="$store.state.Permissions.resourcesView || $store.state.Permissions.resourcesRestrictedView && burstDeliveryType === 'dynamic'">
              Resources
            </v-tab>
            <v-tab v-show="$store.state.Permissions.settingsView && burstDeliveryType === 'dynamic'">
              Settings
            </v-tab>          
            <v-tab v-show="$store.state.Permissions.screenSpecsView">
              Screen Specs
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <!-- View burst and Screen -->
    <v-row
      v-if="tab !== 0"
      class="pt-5"
    >
      <!-- Select Burst -->
      <v-col class="my-0 py-0">
        <v-select
          v-model="selectedBurst"
          outlined
          label="Select Burst"
          :items="campaignBursts"
          item-text="name"
          return-object
          color="primary"
          @change="changeBurst"
        />
      </v-col>
      <!-- Selected Location -->
      <v-col class="pa-0 ma-0">
        <span v-if="$store.state.Permissions.activeLocationView && previewLocation && locationsList">
          <h4>Selected Location</h4>
          <div v-html="prettifyLocationMetadata(previewLocation.name ? previewLocation : locationsList[0])"></div>
        </span>
      </v-col>
      <!-- Select Screen -->
      <v-col class="my-0 py-0">
        <v-select
          v-model="selectedCampaignBurstScreen"
          :loading="loadingScreens"
          outlined
          label="Select Screen"
          :items="sortedCampaignBurstScreens"
          :item-text="item => `${item.screen.mediaOwner.friendlyName} - ${item.screen.name} - ${item.screen.width}x${item.screen.height}`" 
          return-object
          color="primary"
          @change="changeScreen"
          :menu-props="{ maxHeight: '550px' }"
          />
      </v-col>
    </v-row>
    <!-- Display cards + Actions + filters -->
      <v-tabs-items
        v-model="tab"
        style="background: transparent"
        :style="tab === 0 ? {marginTop: '20px'} : ''"
      >
        <!-- Creator -->
        <v-tab-item
          style="background: transparent"
        >
          <UploadMediaTabLBC 
            v-if="$store.state.Permissions.creatorEditor && tab === 0"
          />
        </v-tab-item> 
        <!-- Upload media -->
        <v-tab-item
          style="background: transparent"
        >
          <template v-if="$store.state.Permissions.uploadMediaView && tab === 1">
            <!-- Pagination limit & Upload area -->
            <v-row>
              <!-- Upload area -->
              <v-col
                cols="12"
                class="d-flex justify-end"
                style="margin-top: 2px;"
              >
              <!-- CLIPBOARD-->
              <!-- Empty Clipboard -->
              <v-tooltip top v-if="$store.state.Clipboard.isSelectedCopyTriggers">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3 d-flex justify-center align-center"
                      style="width: 60px; height: 60px; border-radius: 50%;"
                    >
                      <v-btn
                        class="d-flex justify-center align-center"
                        fab
                        color="red darken-3"
                        @click="
                          $store.dispatch('Clipboard/emptyClipboard')
                        "
                      >
                        <v-icon>mdi-clipboard-off</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Empty Clipboard</span>
                </v-tooltip>

                <!-- Global paste triggers -->
                <v-tooltip top v-if="$store.state.Clipboard.isSelectedCopyTriggers && $store.state.Clipboard.selected.length !== 0">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3 d-flex justify-center align-center"
                      style="width: 60px; height: 60px; border-radius: 50%;"
                    >
                      <v-btn
                        :loading="$store.state.Clipboard.pasteLoading"
                        class="d-flex justify-center align-center"
                        fab
                        color="primary"
                        @click="
                          pasteGlobalTriggers($store.state.Clipboard.triggersCopyData)
                        "
                      >
                        <v-icon>mdi-clipboard-text-multiple-outline</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Paste triggers</span>
                </v-tooltip>
                <!-- Select All Screens -->
                <v-tooltip top v-if="$store.state.Clipboard.isSelectedCopyTriggers">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3 d-flex justify-center align-center"
                      style="width: 60px; height: 60px; border-radius: 50%;"
                    >
                      <v-btn
                        class="d-flex justify-center align-center"
                        fab
                        color="primary"
                        @click="selectAllScreensClipboard"
                      >
                        <v-icon color="white">mdi-plus-box-multiple</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Select All Screens</span>
                </v-tooltip>
                <!-- Edit clipboard -->
                <v-tooltip top v-if="$store.state.Permissions.canEditClipboard && $store.state.Clipboard.isSelectedCopyTriggers && $store.state.Clipboard.selected.length !== 0">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3 d-flex justify-center align-center"
                      style="width: 60px; height: 60px; border-radius: 50%;"
                    >
                      <v-btn
                        class="d-flex justify-center align-center"
                        fab
                        color="primary"
                        @click="
                                editClipboardTriggers
                              "
                      >
                        <v-icon color="white">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Edit clipboard</span>
                </v-tooltip>
                <!-- END CLIPBOARD-->
                <!-- Advanced Filters Btn -->
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="mr-3 d-flex justify-center align-center"
                          style="width: 70px; height: 60px; border-radius: 50%;"
                        >
                          <v-btn
                            class="d-flex justify-center align-center"
                            fab
                            :color="(hover || showAdvancedFilters) ? 'primary' : 'gray'"

                            style="transition: 0.2s"
                            @click="showAdvancedFilters = !showAdvancedFilters"
                          >
                            <v-icon>mdi-filter-cog</v-icon>
                          </v-btn>
                        </div>
                    </v-hover>
                    </template>
                    <span>View filters</span>
                  </v-tooltip>
                </div>
                <!-- Draft Placeholder -->
                <div
                  v-if="this.$store.state.Permissions.uploadMedia && this.$store.state.Permissions.placeholderCreate"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="mr-3 d-flex justify-center align-center"
                        style="width: 60px; height: 60px; border-radius: 50%;"
                      >
                        <v-btn
                          class="d-flex justify-center align-center"
                          fab
                          color="primary"
                          @click="showDraftPlaceholderDialog = true"
                        >
                          <v-icon>mdi-image-plus</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Add Placeholder</span>
                  </v-tooltip>
                </div>
                <!-- Social media -->
                <v-tooltip top v-if="showUploadSocial">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="mr-4 d-flex justify-center align-center"
                      style="width: 60px; height: 60px; border-radius: 50%;"
                    >
                      <v-btn
                        class="d-flex justify-center align-center"
                        fab
                        color="primary"
                        @click="showUploadCreativeDialog = true"
                      >
                        <v-icon>mdi-twitter</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Upload Tweets</span>
                </v-tooltip>
                <!-- drag & drop upload -->
                <div
                  v-if="$store.state.Permissions.uploadMedia"
                  @click="$refs.mediaUpload.click()"
                  @dragover.prevent
                  @drop.prevent
                  style="width: 150px;"
                >
                  <input
                    @click="$refs.mediaUpload.value = ''"
                    id="mediaUpload"
                    ref="mediaUpload"
                    type="file"
                    multiple
                    hidden
                    @change="uploadFile"
                  />
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <div
                          ref="dragDiv"
                          v-bind="attrs"
                          v-on="on"
                          @drop="dragFile"
                          @dragleave="$refs.dragDiv.style.backgroundColor = 'transparent'"
                          @dragover="$refs.dragDiv.style.backgroundColor = 'grey'"
                          style="
                            background-color: transparent;
                            padding: 0.66em 0em;
                            transition: 0.1s;
                          "
                          :style="hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}"
                          class="rounded"
                        >
                          <div style="width:100%; text-align:center;">
                            <v-icon large>
                              mdi-cloud-upload
                            </v-icon>
                          </div>
                        </div>
                      </v-hover>
                    </template>
                    <span>Upload Creatives</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <!-- Filters Row -->
            
            <v-row v-if="showAdvancedFilters">
              <!-- Page Limit Filter-->
              <v-col class="pt-6" v-if="screenContents">
                <v-select
                  v-model="limit"
                  outlined
                  :items="pageLimitOptions"
                  @change="pageNum = 1"
                  label="Page Limit"
                />
              </v-col>
              <!-- XML Filter-->
              <v-col class="pt-6" v-if="artworkFilters.length !== 0">
                <v-select
                  v-model="selectedArtWorkFilters"
                  multiple
                  :items="artworkFilters"
                  :label="'Filter XML Styles'"
                  outlined
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">
                        <span>{{ item }}</span>
                    </span>

                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                        (+{{ selectedArtWorkFilters.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- Go Live Filter-->
              <v-col class="pt-6" v-if="isGoLive">
                <v-select
                  v-model="selectedGoLiveValue"
                  outlined
                  label="GoLive"
                  :items="goLiveItems" 
                  return-object
                  color="primary"
                  clearable
                  multiple
                />
              </v-col>
              <!-- Day of the week Filter /  Choose between one or multiple-->
              <v-col class="pt-6" v-if="isDayOfWeek">
                <v-select
                  v-model="selectedDayOfWeekValue"
                  outlined
                  label="Day of the week"
                  :items="dayOfWeekItems" 
                  return-object
                  color="primary"
                  clearable
                  multiple
                />
              </v-col>
              <!-- Region Filter /  Choose between one or multiple-->
              <v-col class="pt-6" v-if="isRegion">
                <v-select
                  v-model="selectedRegionFilters"
                  outlined
                  label="Region"
                  :items="regionFiltersList" 
                  return-object
                  color="primary"
                  clearable
                  multiple
                />
              </v-col>
              <!-- Sort Asc/Desc Filter -->
              <v-col class="pt-6">
                <v-select
                  v-model="selectedSortValue"
                  outlined
                  label="Asc/Desc"
                  :items="[{text: 'Group', value: 'group'}, {text: 'Ascending', value: 'asc'}, {text: 'Descending', value: 'desc'}]"
                  item-text="text"
                  return-object
                  color="primary"
                />
              </v-col>
            </v-row>
            <v-row v-if="showAdvancedFilters">
              <!-- Calendar trigger Filter -->
              <v-col class="pb-6" v-if="isCalendar">
                <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="calendarValue ? calendarValue : ''"
                      label="Calendar"
                      outlined
                      append-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      v-bind="attrs"
                      hide-details
                      clearable
                      @click:clear="calendarValue = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="calendarValue"
                    no-title
                    range
                    scrollable
                  />
                </v-menu>
              </v-col>
              <!-- Time trigger Filter, that takes a range of hours from 00:00 to 24:00 -->
              <v-col class="pb-6 d-flex" v-if="isTime" style="gap: 1em">
                <!-- Start TIme -->
                <v-text-field
                      :value="startTimeValue ? startTimeValue : ''"
                      label="Start Time (hh:mm)"
                      :rules="[v => !!v || 'Start time is required', v => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Start time must be in hh:mm format']"
                      outlined
                      @change="startTimeValue = $event"
                      append-icon="mdi-clock"
                      hide-details
                      clearable
                      @click:clear="startTimeValue = null"
                />
                <!-- End TIme -->
                <v-text-field
                      :value="endTimeValue ? endTimeValue : ''"
                      @change="endTimeValue = $event"
                      label="End Time (hh:mm)"
                      :rules="[v => !!v || 'End time is required', v => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'End time must be in hh:mm format']"
                      outlined
                      append-icon="mdi-clock"
                      hide-details
                      clearable
                      @click:clear="endTimeValue = null"
                />
              </v-col>
            </v-row>
            <!-- Content cards (the uploaded media) -->
            <v-row 
              v-if="screenContents"
            >
              <v-col
                v-for="(screenContent, index) in filteredScreenContents.slice((this.pageNum - 1) * this.limit, this.pageNum * this.limit)"
                :key="`fsi`+index"
                cols="auto"
              >
                <v-card
                  style="height: 100%;"
                  width="360"
                  class="ma-0 pa-0 d-flex flex-column card"
                  v-bind:class="{ flipme: cardOne[screenContent.id] == 'flipped', highlightCard: (selectedContentForTriggerEdit != null) ? screenContent.id == selectedContentForTriggerEdit.id : false}"
                  :style="imageDimensionCheck(screenContent.media).color !== 'green' ? { 'border-top': '5px solid ' + imageDimensionCheck(screenContent.media).color } : ''"
                >
                  <div class="px-4 py-2">
                    <!-- Controls/Info (top of card) -->
                    <v-row 
                      class="d-flex no-wrap"
                      :class="cardOne[screenContent.id] == 'flipped' ? 'card__face--back': ''"
                      >
                      <v-col
                        cols="7"
                        class="my-3 py-0 pr-0 text-left d-flex align-center"
                      >

                        <!-- Delete media -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              v-if="allowScreenMediaRemove"
                              color="red"
                              icon
                              class="mr-3 mt-n1"
                              @click="
                                deleteContentDialog = true;
                                selectedContentForTriggerEdit = screenContent;
                              "
                              style="background-color: transparent;"
                            >
                              <v-icon style="font-size: 21px">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete Screen Content</span>
                        </v-tooltip>

                        <!-- File size -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex"
                            >
                              <span>{{getReadableFileSizeString(screenContent.media.fileSize)}}</span>
                              <v-icon v-if="isFileSizeBigger(screenContent.media.fileSize)" color="red" class="mt-n1 ml-1">mdi-close</v-icon>
                              <v-icon v-else style="color:#4BF70B;" class="mt-n1 ml-1">mdi-check</v-icon>
                            </div>
                            
                          </template>
                          <span>Media Size</span>
                        </v-tooltip>

                        <!-- Dimension mismatch warning -->
                        <v-tooltip 
                          v-if="imageDimensionCheck(screenContent.media).result"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2 mt-n1"
                            >
                            <div
                              class="d-flex align-center justify-center"
                              :style="{
                                position: 'relative',
                              }"
                            >
                              <v-icon
                                style="font-size: 22px"
                                color="gray"
                              >
                                mdi-monitor
                              </v-icon>
                              <v-icon
                                :color="imageDimensionCheck(screenContent.media).color"
                                style=""
                                :style="{
                                  position: 'absolute',
                                  top: '3px',
                                  left: 0,
                                  right: 0,
                                  fontSize: '12px'
                                }"
                              >
                                {{imageDimensionCheck(screenContent.media).icon}}
                              </v-icon>
                            </div>
                            </span>
                          </template>
                          <span>{{imageDimensionCheck(screenContent.media).message}}</span>
                        </v-tooltip>

                        <!-- Download media -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div style="margin-left:10px;"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-btn
                                icon
                                @click.prevent="downloadItem({media: screenContent.media})"
                                style="background-color: transparent;"
                              >
                                <v-icon color="primary" class="mt-n1 ml-1" style="font-size: 23px">
                                  mdi-download-circle
                                </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>Download</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers edit -->
                      <v-col
                        cols="5"
                        class="text-right mt-1 pl-0"
                      >
                        <!-- Timeline Button-->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-hover v-slot="{ hover }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :color="(hover || cardOne[screenContent.id] == 'flipped') ? 'primary' : 'grey'"
                                class="mr-3"
                                icon
                                small
                                @click="toggleTimeline(screenContent.id)"
                              >
                                <v-icon>mdi-timeline-text-outline</v-icon>
                              </v-btn>
                            </v-hover>
                          </template>
                          <span>View timeline</span>
                        </v-tooltip>
                        <!-- DUPLICATE BUTTON-->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="$store.state.Clipboard.isSelectedCopyTriggers && $store.state.Clipboard.triggersCopyData.id === screenContent.id"
                              v-bind="attrs"
                              v-on="on"
                              v-if="allowTriggersEdit"
                              v-bind:color="checkSelectedList(screenContent.id) ? 'red darken-3' : 'primary'"
                              class="mr-3"
                              icon
                              small
                              @click="
                                addSelectedList(screenContent)
                                selectedTriggersCopy(screenContent)
                              "
                            >
                              <v-icon v-if="!$store.state.Clipboard.isSelectedCopyTriggers || $store.state.Clipboard.triggersCopyData.id === screenContent.id">mdi-clipboard-text-outline</v-icon>
                              <v-icon v-else-if="checkSelectedList(screenContent.id)">mdi-clipboard-check-outline</v-icon>
                              <v-icon v-else>mdi-clipboard-outline</v-icon>
                            </v-btn>
                          </template>
                          <span v-if="$store.state.Clipboard.isSelectedCopyTriggers && !checkSelectedList(screenContent.id)">Add to clipboard</span>
                          <span v-else-if="checkSelectedList(screenContent.id)">Remove from clipboard</span>
                          <span v-else>Copy Triggers</span>
                        </v-tooltip>

                        <!-- Edit ALL BUTTON-->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              v-if="allowTriggersEdit && screenLevelTriggers.length !== 0"
                              color="primary"
                              icon
                              small
                              @click="
                                selectedContentForTriggerEdit = screenContent
                                openOnSequence = false
                                triggerBuilderDialog = true
                                openTriggerEdit('')
                              "
                            >
                              <v-icon>mdi-cog</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Triggers</span>
                        </v-tooltip>
                      </v-col>
                      <v-col style="margin-top:-50px;margin-bottom:-20px" cols="12">
                        <SOVSlider
                            :campaign="screenContent"
                            :sliderGroups="sliderGroups"
                            :key="screenContent.id"
                            :interLinked="false"
                            :type="`horizontal`"
                            :tooltip="true"
                            :lockSliderView="true"
                            style="width:100%"/>
                      </v-col>
                    </v-row>

                    <!-- Raw media preview (middle of card) -->
                    <v-row 
                      class="pt-3"
                      :class="cardOne[screenContent.id] == 'flipped' ? 'card__face--back': 'card__face'"
                    >
                      <div
                        v-if="['mp4', 'webm', 'ogg', 'mov', 'mpg'].includes(screenContent.media.urlThumb.split('.').pop().toLowerCase())"
                        class="d-flex justify-center align-center"
                        style="width: 100%; object-fit: cover; cursor: pointer"
                      >
                        <video
                          style="
                            max-height: 250px;
                            max-width: 330px;
                            margin-left: auto;
                            margin-right: auto;
                            display: block;
                          "
                          :src="screenContent.media.urlThumb"
                          controls
                          @click="openImage(screenContent.media.url)"
                        ></video>
                        <!-- Display download btn -->
                        <v-tooltip v-if="isMediaPlanner" top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                  v-bind="attrs"
                                  v-on="on"
                                  :style="($vuetify.theme.dark) ? 
                                    {
                                      position: 'absolute',
                                      top: '79px',
                                      right: '0px',
                                      opacity: 0.75,
                                    } : {
                                      position: 'absolute',
                                      top: '79px',
                                      right: '0px',
                                      opacity: 0.75,
                                    }"
                                >
                                  <v-btn 
                                    fab 
                                    small
                                    @click.stop="
                                      artworkReplace = true
                                      selectedArtToRepl = screenContent.id
                                      clickFileUpload()
                                    "
                                    @drop="(event) => {
                                      artworkReplace = true
                                      selectedArtToRepl = screenContent.id
                                      dragFile(event)
                                    }"
                                    @dragleave="dragFileUpload('dragDiv' + index, 'transparent'); dragFilePlusUpload('dragDivPlus' + index, 'hide');"
                                    @dragover="dragFileUpload( 'dragDiv' + index, 'grey'); dragFilePlusUpload('dragDivPlus' + index, 'show');"
                                    @mouseleave="dragFileUpload( 'dragDiv' + index, 'transparent'); dragFilePlusUpload('dragDivPlus' + index, 'hide');"
                                    @mouseover="dragFileUpload( 'dragDiv' + index, 'grey'); dragFilePlusUpload('dragDivPlus' + index, 'show');"
                                    @dragover.prevent
                                    @drop.prevent
                                    :style="{
                                      cursor: 'pointer'
                                    }"
                                    >
                                      <v-icon>mdi-upload</v-icon>
                                  </v-btn>
                                </div>
                            </template>
                            <span>Replace Artwork</span>
                          </v-tooltip>
                      </div>
                      <div v-if="['mp3', 'wav', 'ogg'].includes(screenContent.media.urlThumb.split('.').pop().toLowerCase())">
                        <audio controls>
                          <source
                            :src="screenContent.media.urlThumb"
                            type="audio/mpeg"
                          >
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                      <div 
                        v-if="['jpg', 'png', 'gif', 'webp', 'jpeg'].includes(screenContent.media.urlThumb.split('.').pop().toLowerCase())" 
                        class="d-flex justify-center align-center" style="width: 100%"
                        :class="!$store.state.Permissions.advancedIndicatorView ? 'mb-5' : ''"
                      >
                        <v-img
                          contain
                          :style="{position: 'relative', cursor: 'pointer'}"
                          :src="screenContent.media.urlThumb"

                          max-width="330"
                          max-height="250"
                          @click="openImage(screenContent.media.url)"
                        >
                           <!-- Display download btn -->
                           <v-tooltip v-if="isMediaPlanner" top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                  v-bind="attrs"
                                  v-on="on"
                                  :style="($vuetify.theme.dark) ? 
                                    {
                                      position: 'absolute',
                                      top: '5px',
                                      right: '0px',
                                      opacity: 0.75,
                                    } : {
                                      position: 'absolute',
                                      top: '5px',
                                      right: '0px',
                                      opacity: 0.75,
                                    }"
                                >
                                  <v-btn 
                                    fab 
                                    small
                                    @click.stop="
                                      artworkReplace = true
                                      selectedArtToRepl = screenContent.id
                                      clickFileUpload()
                                    "
                                    @drop="(event) => {
                                      artworkReplace = true
                                      selectedArtToRepl = screenContent.id
                                      dragFile(event)
                                    }"
                                    @dragleave="dragFileUpload('dragDiv' + index, 'transparent'); dragFilePlusUpload('dragDivPlus' + index, 'hide');"
                                    @dragover="dragFileUpload( 'dragDiv' + index, 'grey'); dragFilePlusUpload('dragDivPlus' + index, 'show');"
                                    @mouseleave="dragFileUpload( 'dragDiv' + index, 'transparent'); dragFilePlusUpload('dragDivPlus' + index, 'hide');"
                                    @mouseover="dragFileUpload( 'dragDiv' + index, 'grey'); dragFilePlusUpload('dragDivPlus' + index, 'show');"
                                    @dragover.prevent
                                    @drop.prevent
                                    :style="{
                                      cursor: 'pointer'
                                    }"
                                    >
                                      <v-icon>mdi-upload</v-icon>
                                  </v-btn>
                                </div>
                            </template>
                            <span>Replace Artwork</span>
                          </v-tooltip>
                        </v-img>
                      </div>
                      
                      <div v-if="$store.state.Permissions.advancedIndicatorView" class="my-5 text-center" style="width: 100%">
                        <div
                          style="width: 100%;"
                          @click="openImage(screenContent.media.url)"
                          class="text-center"
                        >
                        <v-tooltip top>
                            <template v-slot:activator="{ attrs, on }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="text-center"
                          >
                            {{screenContent.media.originalFileName}}
                          </div>
                          </template>
                          <span>Open image in new tab</span>
                          </v-tooltip>
                        </div>
                    
                        <div
                          v-if="burstDeliveryType === 'linear'"
                          style="width: 100%; color: grey; cursor: pointer;"
                          class="text-center" 
                          @click="copyText(screenContent.media.id)" 
                        >
                          <button>
                            <span>MediaID: {{screenContent.media.id}}</span>
                            <span class="mdi mdi-content-copy"></span>
                          </button>
                        </div>
                      </div>
                    </v-row>

                    <!-- Instagram -->
                    <div v-if="screenContent.triggers.instagram !== undefined">
                      <v-col
                        cols="12"
                        class="text-center my-0 py-2"
                      >
                        <v-icon>mdi-account</v-icon>
                        {{ screenContent.triggers.instagram.username }}
                      </v-col>
                      <v-col
                        v-if="screenContent.triggers.instagram.text_nodes[0]"
                        cols="12"
                        class="text-center my-0 py-0"
                      >
                        <v-icon>mdi-instagram</v-icon>
                        {{ screenContent.triggers.instagram.text_nodes[0].node.text }}
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-center my-0 py-2"
                        style="color: #808080"
                      >
                        <a
                          :href="
                            'https://www.instagram.com/p/' +
                              screenContent.triggers.instagram.shortcode
                          "
                          target="_blank"
                        >Open on Instagram</a>
                      </v-col>

                      <v-col
                        cols="12"
                        class="text-center my-0 py-0"
                        style="color: #808080"
                      >
                        {{
                          screenContent.triggers.instagram.taken_at_timestamp | momentUnix
                        }}
                      </v-col>
                    </div>

                    <!-- Twitter -->
                    <div v-else-if="screenContent.triggers.tweet !== undefined">
                      <v-col
                        cols="12"
                        class="text-center my-0 py-2"
                      >
                        <v-icon>mdi-account</v-icon>
                        <a
                          :href="
                            'https://twitter.com/' +
                              screenContent.triggers.tweet.user_screen_name
                          "
                          target="_blank"
                          class="twitter-text"
                        >{{ screenContent.triggers.tweet.user_screen_name }}</a>
                        
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-center my-0 py-0"
                      >
                        <v-icon>mdi-twitter</v-icon>
                        <a
                          :href="
                            'https://twitter.com/i/web/status/' +
                              screenContent.triggers.tweet.id
                          "
                          target="_blank"
                          class="twitter-text"
                        >{{ screenContent.triggers.tweet.full_text }}</a>
                      </v-col>
                      <div
                        class="text-center my-0 py-0 d-flex justify-space-between"
                        style="color: #808080"
                      >
                        <span></span>
                        <a
                          :href="
                            'https://twitter.com/i/web/status/' +
                              screenContent.triggers.tweet.id
                          "
                          target="_blank"
                          class="twitter-date"
                        >{{ screenContent.triggers.tweet.created_at | momentHuman }}</a>
                        <v-icon
                          @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('socialMedia')"
                          v-if="screenContent.triggers['socialMedia'] !== undefined"
                          class="align-content-end mouse-pointer"
                          :style="
                            screenContent.triggers['socialMedia']['profanityChecks'] === '' 
                            || screenContent.triggers['socialMedia']['profanityChecks'] === undefined
                            ? { color: 'grey' }
                            : { color: '#F7B900' }
                          "
                        >
                          pat-profanity
                        </v-icon>
                      </div>
                    </div>

                    <!-- Heroes -->
                    <div
                      v-if="
                        screenContent.triggers.hero !== undefined ||
                        screenContent.triggers.highlight !== undefined
                      "
                      :class="cardOne[screenContent.id] == 'flipped' ? 'card__face--back': 'card__face'"
                    >
                      <div
                        v-if="
                          screenContent.triggers.hero == 'INSTAGRAM' ||
                            screenContent.triggers.highlight == 'INSTAGRAM'
                        "
                      >
                        <v-icon>mdi-instagram</v-icon>
                        <v-col
                          cols="12"
                          class="text-center my-0 py-2"
                        >
                          <v-icon>mdi-account</v-icon>
                          {{ screenContent.triggers.username }}
                        </v-col>
                        <v-col
                          cols="12"
                          class="text-center my-0 py-2"
                          style="color: #808080"
                        >
                          <a
                            :href="
                              'https://www.instagram.com/p/' +
                                screenContent.triggers.post_id
                            "
                            target="_blank"
                          >Open on Instagram</a>
                        </v-col>

                        <v-col
                          cols="12"
                          class="text-center my-0 py-0"
                          style="color: #808080"
                        >
                          Post Date: {{ screenContent.triggers.post_date | momentUnix }}
                        </v-col>
                      </div>
                      <div
                        v-if="
                          screenContent.triggers.hero == 'TWITTER' ||
                            screenContent.triggers.highlight == 'TWITTER'
                        "
                      >
                        <v-col
                          cols="12"
                          class="text-center my-0 py-2"
                        >
                          <v-icon>mdi-twitter</v-icon>
                          <v-icon>mdi-account</v-icon>
                          {{ screenContent.triggers.username }}
                        </v-col>
                        <v-col
                          cols="12"
                          class="text-center my-0 py-2"
                          style="color: #808080"
                        >
                          <a
                            :href="
                              'https://twitter.com/i/web/status/' +
                                screenContent.triggers.post_id
                            "
                            target="_blank"
                          >Open on Twitter</a>
                        </v-col>
                        <v-col
                          cols="12"
                          class="text-center my-0 py-0"
                          style="color: #808080"
                        >
                          Post Date: {{ screenContent.triggers.post_date | momentHuman }}
                        </v-col>
                      </div>
                    </div>
                    <div
                      class="mouse-pointer"
                      v-if="screenLevelTriggers.includes('heroTriggers') && screenContent.triggers.heroTriggers"
                      @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('heroTriggers')"
                      :class="cardOne[screenContent.id] == 'flipped' ? 'card__face--back': 'card__face'"
                    >
                      <div
                        v-if="
                          screenContent.triggers.heroTriggers.platform === 'Email'"
                      >
                        <v-col
                          cols="12"
                          class="text-center my-0 py-2"
                        >
                          <v-icon>mdi-mail</v-icon>
                          
                          {{ screenContent.triggers.heroTriggers.userIdentifier }}
                        </v-col>
                        <v-col
                          cols="12"
                          class="text-center my-0 py-2"
                          style="color: #808080"
                        >
                          <v-icon>mdi-account</v-icon>
                          {{ screenContent.triggers.heroTriggers.fullName }}
                        </v-col>
                        <v-col
                          cols="12"
                          class="text-center my-0 py-0"
                          style="color: #808080"
                        >
                          <span v-if="screenContent.triggers.hero === undefined">
                            <v-icon :style="isTrue(screenContent.triggers.heroTriggers.generateHero) ? { color: 'green' } : { color: 'red' }">pat-hero</v-icon>
                          </span>
                          <span v-if="screenContent.triggers.hero !== undefined">
                            <v-icon :style="isTrue(screenContent.triggers.heroTriggers.publishHero) ? { color: 'green' } : { color: 'red' }">mdi-email</v-icon>
                          </span>
                        </v-col>
                      </div>
                    </div>

                    <!-- UEFA -->
                    <v-row>
                      <v-col
                        v-if="screenContent.triggers['uefaTriggers'] !== undefined"
                        cols="12"
                        class="text-right"
                      >
                        <v-icon
                          v-if="
                            screenContent.triggers['uefaTriggers'] !== undefined &&
                              showFilterBar === true
                          "
                          large
                          :style="
                            screenContent.triggers['uefaTriggers'][
                              'uefaProfanityChecks'
                            ] === '' ||
                              screenContent.triggers['uefaTriggers'][
                                'uefaProfanityChecks'
                              ] === undefined
                              ? { color: 'grey' }
                              : { color: '#F7B900' }
                          "
                        >
                          pat-profanity
                        </v-icon>
                      </v-col>
                    </v-row>

                  </div>
                  
                  <!-- Triggers -->
                  <div v-if="cardOne[screenContent.id] == 'start'" class="card__face card__face--front">
                    <!-- Triggers: UI Indicators -->
                    <v-row class="mb-3">
                      <!-- Triggers: Caption -->
                      <v-col
                        v-if="screenLevelTriggers.includes('caption') && screenContent.triggers.caption"
                        cols="12"
                        class="d-flex align-center justify-left"
                      >
                      <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div 
                              v-bind="attrs"
                              v-on="on"
                            >
                              <div
                                style="cursor: pointer"
                                class="mx-8"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('caption')"
                              >
                                <span>
                                  <i class="mdi mdi-comment-text" aria-hidden="true"></i>
                                </span>
                                <span
                                    v-text="limitText(screenContent.triggers.caption.copy,46,'-No Caption-')"
                                  />
                              </div>
                            </div>
                          </template>
                          <span>On Screen Caption</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: Calendar (Month + date) -->
                      <v-col
                        v-if="screenLevelTriggers.includes('calendar') && screenContent.triggers.calendar"
                        cols="6"
                        class="d-flex align-center justify-center"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div 
                              v-bind="attrs"
                              v-on="on"
                              class="mouse-pointer d-flex justify-space-between py-2"
                              style="width: 130px; border-bottom: 10px solid #00ff00;"
                              @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('calendar')"
                            >
                              <p class="ma-0 pr-3">{{screenContent.triggers.calendar.startDate | momentScreenMonth('s')}}</p>
                              <p class="ma-0 pl-3">{{screenContent.triggers.calendar.endDate | momentScreenMonth('e')}}</p>
                            </div>
                          </template>
                          <span>Calendar Trigger</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: Days of the week -->
                      <v-col
                        v-if="screenLevelTriggers.includes('dayOfWeek') && screenContent.triggers.dayOfWeek"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-center justify-center"
                            >
                              <div 
                                class="mouse-pointer d-flex justify-center py-2 mx-1"
                                :style="{fontSize: '12px', width: '10px', borderBottom: isTrue(screenContent.triggers.dayOfWeek.monday) ? '10px solid #00ff00' : '10px solid #98341f'}"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('dayOfWeek')"
                              >
                                <p class="ma-0">M</p>
                              </div>
                              <div 
                                class="mouse-pointer d-flex justify-center py-2 mx-1"
                                :style="{fontSize: '12px', width: '10px', borderBottom: isTrue(screenContent.triggers.dayOfWeek.tuesday) ? '10px solid #00ff00' : '10px solid #98341f'}"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('dayOfWeek')"
                              >
                                <p class="ma-0">T</p>
                              </div>
                              <div 
                                class="mouse-pointer d-flex justify-center py-2 mx-1"
                                :style="{fontSize: '12px', width: '10px', borderBottom: isTrue(screenContent.triggers.dayOfWeek.wednesday) ? '10px solid #00ff00' : '10px solid #98341f'}"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('dayOfWeek')"
                              >
                                <p class="ma-0">W</p>
                              </div>
                              <div 
                                class="mouse-pointer d-flex justify-center py-2 mx-1"
                                :style="{fontSize: '12px', width: '10px', borderBottom: isTrue(screenContent.triggers.dayOfWeek.thursday) ? '10px solid #00ff00' : '10px solid #98341f'}"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('dayOfWeek')"
                              >
                                <p class="ma-0">T</p>
                              </div>
                              <div 
                                class="mouse-pointer d-flex justify-center py-2 mx-1"
                                :style="{fontSize: '12px', width: '10px', borderBottom: isTrue(screenContent.triggers.dayOfWeek.friday) ? '10px solid #00ff00' : '10px solid #98341f'}"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('dayOfWeek')"
                              >
                                <p class="ma-0">F</p>
                              </div>
                              <div 
                                class="mouse-pointer d-flex justify-center py-2 mx-1"
                                :style="{fontSize: '12px', width: '10px', borderBottom: isTrue(screenContent.triggers.dayOfWeek.saturday) ? '10px solid #00ff00' : '10px solid #98341f'}"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('dayOfWeek')"
                              >
                                <p class="ma-0">S</p>
                              </div>
                              <div 
                                class="mouse-pointer d-flex justify-center py-2 mx-1"
                                :style="{fontSize: '12px', width: '10px', borderBottom: isTrue(screenContent.triggers.dayOfWeek.sunday) ? '10px solid #00ff00' : '10px solid #98341f'}"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('dayOfWeek')"
                              >
                                <p class="ma-0">S</p>
                              </div>
                            </div>
                          </template>
                          <span>Day of week Trigger</span>
                        </v-tooltip>
                      </v-col>
                      
                      <!-- Triggers: Time -->
                      <v-col
                        v-if="screenLevelTriggers.includes('time') && screenContent.triggers.time"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div 
                              v-bind="attrs"
                              v-on="on" 
                              class="d-flex align-center justify-center"
                            >
                              <div 
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('time')"
                                class="mouse-pointer d-flex justify-space-between pb-2"
                                style="width: 130px; border-bottom: 10px solid #00ff00;"
                              >
                                <p class="ma-0 pr-3">{{(screenContent.triggers.time && screenContent.triggers.time.startTime) ? screenContent.triggers.time.startTime : 'hh:mm'}}</p>
                                <p class="ma-0 pl-3">{{(screenContent.triggers.time && screenContent.triggers.time.endTime) ? screenContent.triggers.time.endTime : 'hh:mm'}}</p>
                              </div>
                            </div>
                          </template>
                          <span>Time Trigger</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: DateRange -->
                      <v-col
                        v-if="screenLevelTriggers.includes('dateRange') && screenContent.triggers.dateRange"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div 
                              v-bind="attrs"
                              v-on="on" 
                              class="d-flex align-center justify-center"
                            >
                              <div 
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('dateRange')"
                                class="mouse-pointer d-flex justify-space-between pb-2"
                              >
                                <p class="ma-0 pr-3">Show: {{toDateRangeText(screenContent.triggers.dateRange.startEndDateRange)}}</p>
                              </div>
                            </div>
                          </template>
                          <span>Date Range Trigger</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: Duration -->
                      <v-col
                        v-if="screenLevelTriggers.includes('duration') && screenContent.triggers.duration"
                        cols="6"
                        class="d-flex align-center justify-center"
                        
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div 
                              v-bind="attrs"
                              v-on="on"
                            >
                              <p 
                                class="mouse-pointer ma-0 text-uppercase ml-1"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('duration')"
                              >duration:</p>
                              
                              <div 
                                class="mouse-pointer d-flex"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('duration')"
                              >
                                <v-icon color="#00ff00">mdi-timer-sand</v-icon>
                                <p class="ma-0">{{(screenContent.triggers.duration.durationSeconds !== '0' && screenContent.triggers.duration.durationSeconds !== 0 && screenContent.triggers.duration.durationSeconds !== '') ? screenContent.triggers.duration.durationSeconds + ' secs' : 'Full Length'}}</p>
                              </div>
                            </div>
                          </template>
                          <span>Duration Trigger</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: Region -->
                      <v-col
                        v-if="screenLevelTriggers.includes('region') && screenContent.triggers.region"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-center justify-center"
                              @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('region')"
                            >
                              <div style="width: 120px">
                                <div class="d-flex justify-center">
                                  <div class="text-capitalize">{{regionTitle(screenContent.triggers.region.regions)}}</div>
                                </div>
                                <div class="d-flex justify-center">
                                  <div class="d-flex justify-center align-start">
                                    <v-icon 
                                    :color="
                                      (screenContent.triggers.region.regions === 'default' || screenContent.triggers.region.regions === '') 
                                      ? '#98341f'
                                      : '#00ff00'
                                    "
                                    >mdi-earth</v-icon>
                                    <div>
                                      <div
                                        v-for="(region, index) in safeListSplit(screenContent.triggers.region.regions)" 
                                        :key="`sls1`+index"
                                        class="mx-1"
                                      >
                                        <div v-if="(regionExpand) ? true : index < 3 ">
                                          {{region}}
                                        </div>
                                      </div>

                                      <div
                                        v-for="(anitRegion, index) in safeListSplit(screenContent.triggers.region.antiRegions)" 
                                        :key="`sls2`+index"
                                        class="mx-1"
                                      >
                                        <div v-if="(regionExpand) ? true : index < 3 ">
                                          <del>{{anitRegion}}</del>
                                        </div>
                                      </div>
                                      
                                      <div 
                                        v-if="safeListSplit(screenContent.triggers.region.regions).length > 3 || safeListSplit(screenContent.triggers.region.antiRegions).length > 3"
                                        @click.stop="regionExpand = !regionExpand"
                                        class="d-flex justify-center mx-1"
                                      >
                                        <v-icon>{{ (regionExpand) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <span>Region Trigger</span>
                        </v-tooltip>
                      </v-col>

                      
                      <!-- Triggers: Custom Descriptors -->
                      <v-col
                        v-if="screenLevelTriggers.includes('customDescriptors') && screenContent.triggers.customDescriptors"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-left justify-left mouse-pointer"
                              @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('customDescriptors')"
                            >
                              <div class="d-flex justify-center">
                                <div class="d-flex justify-center align-start">
                                  <v-icon 
                                  :color="
                                    (customDescriptorsEmpty(screenContent)) 
                                    ? '#98341f'
                                    : '#00ff00'
                                  "
                                  >mdi-image-text</v-icon>
                                  <small>
                                  <table>
                                    <tr>
                                      <td style="text-align: right; color: #a5a5a5">Campaign:</td>
                                      <td>{{screenContent.triggers.customDescriptors.campaignName}}</td>
                                    </tr>
                                    <tr>
                                      <td style="text-align: right; color: #a5a5a5">Brand:</td>
                                      <td>{{screenContent.triggers.customDescriptors.brandName}}</td>
                                    </tr>
                                    <tr>
                                      <td style="text-align: right; color: #a5a5a5">Creative:</td>
                                      <td>{{screenContent.triggers.customDescriptors.creativeName}}</td>
                                    </tr>
                                    <tr>
                                      <td style="text-align: right; color: #a5a5a5">Order #:</td>
                                      <td>{{screenContent.triggers.customDescriptors.orderNumber}}</td>
                                    </tr>
                                  </table>
                                  </small>
                                </div>
                              </div>
                            </div>
                          </template>
                          <span>Descriptors</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: CSS Styles -->
                      <v-col
                        v-if="screenLevelTriggers.includes('style') && screenContent.triggers.style"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-center justify-center"
                              @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('style')"
                            >
                              <div style="width: 120px">
                                <div class="d-flex justify-center">
                                  <div>XML Styles:</div>
                                </div>
                                <div class="d-flex justify-center">
                                  <div class="d-flex justify-content">
                                    <v-icon :color="(screenContent.triggers.style.xml_styles) ? '#00ff00' : '#98341f'">mdi-xml</v-icon>
                                    <span class="mx-1">{{(screenContent.triggers.style.xml_styles) ? screenContent.triggers.style.xml_styles : 'none'}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <span>XML Styles Trigger</span>
                        </v-tooltip>
                      </v-col>
                      
                      <!-- Triggers: Location Lite (Text) -->
                      <v-col
                        v-if="screenLevelTriggers.includes('locationLite') && screenContent.triggers.locationLite"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-center justify-center pl-4"
                              @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('locationLite')"
                              style="width: 100%;"
                            >
                              <div>
                                <div class="d-flex justify-center">
                                  <v-icon :color="(isTrue(screenContent.triggers.locationLite.copyOn)) ? '#00ff00' : '#98341f'">mdi-text</v-icon>
                                  <div>Copy:</div>
                                </div>
                                <div class="d-flex justify-center">
                                  <div class="d-flex justify-content" :style="{ overflowWrap: 'anywhere'}" >
                                    <span class="mx-1 text-caption" v-html="(screenContent.triggers.locationLite.textDecoration) ? screenContent.triggers.locationLite.textDecoration : 'Empty'"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <span style="width: 100%">Location Lite Copy {{(isTrue(screenContent.triggers.locationLite.copyOn)) ? 'on' : 'off'}}</span>
                        </v-tooltip>
                      </v-col>
                      
                      <!-- Triggers: GoLive Trigger -->
                      <v-col
                        v-if="screenLevelTriggers.includes('golive') && screenContent.triggers.golive"
                        cols="6"
                        class="d-flex align-center justify-center"
                      >
                      <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div 
                              v-bind="attrs"
                              v-on="on"
                            >
                              <div
                                style="cursor: pointer"
                                :class="(getGoLivePublishState(screenContent.triggers.golive.islive) == 'Published') ? 'green--text' : 'red--text'"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('golive')"
                              >
                                <span>
                                  <i class="mdi mdi-flash" aria-hidden="true"></i>
                                </span>
                                <span
                                    v-text="(getGoLivePublishState(screenContent.triggers.golive.islive) !== 'Published') ? getGoLivePublishState(screenContent.triggers.golive.islive)+' Only' : getGoLivePublishState(screenContent.triggers.golive.islive)"
                                  />
                              </div>
                            </div>
                          </template>
                          <span>Published/Preview Only/Draft Only</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: Restrictions -->
                      <v-col
                        v-if="screenLevelTriggers.includes('restrictions') && screenContent.triggers.restrictions"
                        cols="12"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-center justify-center"
                            >
                              <div 
                                class="mouse-pointer flex-lg-row justify-center py-2 mx-4"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('restrictions')"
                              >
                                <div class="mx-0" :style="{fontSize: '12px'}">HFSS</div>
                                <div class="my-auto d-flex justify-center">
                                  <div :style="{fontSize: '12px', marginTop: '10px', width: '10px', height: '10px', borderBottom: isTrue(screenContent.triggers.restrictions.hfss) ? '10px solid #00ff00' : '10px solid #98341f'}"></div>
                                </div>
                              </div>
                              <div 
                                class="mouse-pointer flex-lg-row justify-center py-2 mx-4"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('restrictions')"
                              >
                                <div class="mx-0" :style="{fontSize: '12px'}">Alcohol &amp; Smoking</div>
                                <div class="my-auto d-flex justify-center">
                                  <div :style="{fontSize: '12px', marginTop: '10px', width: '10px', height: '10px', borderBottom: isTrue(screenContent.triggers.restrictions.alcohol) ? '10px solid #00ff00' : '10px solid #98341f'}"></div>
                                </div>
                              </div>
                              <div 
                                class="mouse-pointer flex-lg-row justify-center py-2 mx-4"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('restrictions')"
                              >
                                <div class="ma-0" :style="{fontSize: '12px'}">Cultural</div>
                                <div class="my-auto d-flex justify-center">
                                  <div :style="{fontSize: '12px', marginTop: '10px', width: '10px', height: '10px', borderBottom: isTrue(screenContent.triggers.restrictions.modesty) ? '10px solid #00ff00' : '10px solid #98341f'}"></div>
                                </div>
                              </div>
                              <div 
                                class="mouse-pointer flex-lg-row justify-center py-2 mx-4"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('restrictions')"
                              >
                                <div class="ma-0" :style="{fontSize: '12px'}">Regulatory</div>
                                <div class="my-auto d-flex justify-center">
                                  <div :style="{fontSize: '12px', marginTop: '10px', width: '10px', height: '10px', borderBottom: isTrue(screenContent.triggers.restrictions.regulatory) ? '10px solid #00ff00' : '10px solid #98341f'}"></div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <span>Restrictions Trigger</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: Restrictions -->
                      <v-col
                        v-if="screenLevelTriggers.includes('restrictionsCompact') && screenContent.triggers.restrictionsCompact"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-center justify-center"
                            >
                              <div 
                                class="mouse-pointer flex-lg-row justify-center py-2 mx-4"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('restrictionsCompact')"
                              >
                                <div class="my-auto d-flex justify-center">
                                  <div class="my-auto d-flex justify-center" :style="{fontSize: '12px'}">Contains Sensitive Content</div>
                                  <div :style="{fontSize: '12px', marginTop: '10px', width: '10px', height: '10px', borderBottom: isTrue(screenContent.triggers.restrictionsCompact.restricted) ? '10px solid #98341f' : '10px solid #00ff00'}"></div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <span>Restrictions Compact Trigger</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: hyperLocal -->
                      <v-col
                        v-if="screenLevelTriggers.includes('hyperLocal') && screenContent.triggers.hyperLocal"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-center justify-center"
                            >
                              <div 
                                class="mouse-pointer flex-lg-row justify-center py-2 mx-4"
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('hyperLocal')"
                              >
                                <div class="my-auto d-flex justify-center">
                                  <div class="d-flex justify-center" style="padding-bottom: 1px;">
                                    <v-icon v-if="hasHyperlocalData(screenContent)" class="mx-1" :color="'#00ff00'">mdi-pin</v-icon>
                                    <v-icon v-if="!hasHyperlocalData(screenContent)" class="mx-1" :color="'#98341f'">mdi-pin-off</v-icon>
                                  </div>
                                  <div class="my-auto d-flex justify-center" :style="{fontSize: '12px'}">Hyper Local</div>
                              </div>
                            </div>
                          </div>
                          </template>
                          <span>Hyper Local Trigger</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Triggers: Transit -->
                      <v-col
                        v-if="screenLevelTriggers.includes('transit') && screenContent.triggers.transit"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-center justify-center"
                              @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('transit')"
                            >
                              <div style="width: 120px">
                                <div class="d-flex justify-center" style="padding-bottom: 1px;">
                                  <v-icon class="mx-1" :color="(isActiveTransitIcon(screenContent.triggers.transit.mode, 'line of sight')) ? '#00ff00' : '#98341f'">mdi-map-marker-distance</v-icon>
								                  <v-icon class="mx-1" :color="(isActiveTransitIcon(screenContent.triggers.transit.mode, 'walking')) ? '#00ff00' : '#98341f'">mdi-walk</v-icon>
                                  <v-icon class="mx-1" :color="(isActiveTransitIcon(screenContent.triggers.transit.mode, 'bicycling')) ? '#00ff00' : '#98341f'">mdi-bike</v-icon>
                                  <v-icon class="mx-1" :color="(isActiveTransitIcon(screenContent.triggers.transit.mode, 'driving')) ? '#00ff00' : '#98341f'">mdi-car</v-icon>
                                </div>
                                <div class="d-flex justify-space-between">
                                  <div>{{screenContent.triggers.transit.minSeconds}} - {{screenContent.triggers.transit.maxSeconds}}</div>
                                  <div>{{(parseInt(screenContent.triggers.transit.minSeconds.toString().length) + parseInt(screenContent.triggers.transit.maxSeconds.toString().length) > 6) ? 'secs' : 'seconds'}}</div>
                                </div>
                                <div class="d-flex justify-space-between">
                                  <div>{{screenContent.triggers.transit.minMetres}} - {{screenContent.triggers.transit.maxMetres}}</div>
                                  <div>{{(screenContent.triggers.transit.minMetres.toString().length > 1 || screenContent.triggers.transit.maxMetres.toString().length > 6) ? 'mtrs' : 'metres'}}</div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <span>Transit Trigger</span>
                        </v-tooltip>
                      </v-col>
                      
                      <!-- Triggers: TescoHours -->
                      <v-col
                        v-if="screenLevelTriggers.includes('tescoHours') && screenContent.triggers.tescoHours"
                        cols="6"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div 
                              v-bind="attrs"
                              v-on="on" 
                              class="flex-lg-row align-center justify-center pl-4"
                              :style="{fontSize: '10px'}" 
                            >
                              <div 
                                @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('tescoHours')"
                                class="mouse-pointer flex-lg-row justify-space-between pa-0 col-12 ma-1 text-left"
                                style="width: 90%; border-bottom: 2px solid #00ff00;"
                              >
                                <div>Tesco Status: {{(screenContent.triggers.tescoHours && screenContent.triggers.tescoHours.storeStatus) ? screenContent.triggers.tescoHours.storeStatus : ''}}</div>
                                <div>Types: {{(screenContent.triggers.tescoHours && screenContent.triggers.tescoHours.storeTypes) ? screenContent.triggers.tescoHours.storeTypes : ''}}</div>
                                <div class="d-flex">
                                  <p class="col-6 ma-0 pa-0"><v-icon color="green">mdi-door-open</v-icon>{{(screenContent.triggers.tescoHours && screenContent.triggers.tescoHours.storePreOpen) ? screenContent.triggers.tescoHours.storePreOpen : '0'}} mins</p>
                                  <p class="col-6 ma-0 pa-0 text-right">{{(screenContent.triggers.tescoHours && screenContent.triggers.tescoHours.storePreClose) ? screenContent.triggers.tescoHours.storePreClose : '0'}} mins <v-icon color="red">mdi-door-closed-lock</v-icon></p>
                                </div>
                              </div>
                              <div class="px-1">{{(screenContent.triggers.tescoHours && screenContent.triggers.tescoHours.storeLocateMethod) ? screenContent.triggers.tescoHours.storeLocateMethod : ''}}</div>
                            </div>
                            
                          </template>
                          <span>Tesco</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <!-- Triggers: Sequence -->
                    <div
                      v-if="screenLevelTriggers.includes('sequence') && screenContent.triggers.sequence"
                      @click="selectedContentForTriggerEdit = screenContent; triggerBuilderDialog = true; openTriggerEdit('sequence')"
                      :style="{height: '50px', color: sanitizeGroupFab(screenContent.triggers.sequence.group, 'text-color') , background: sanitizeGroupFab(screenContent.triggers.sequence.group, 'color')}"
                    >

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div 
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex justify-space-between align-center sequence-group rounded-b"
                            style="height: 100%"
                          >
                            <p class="mouse-pointer ma-0 ml-2">
                              Group: {{ sanitizeGroupFab(screenContent.triggers.sequence.group, 'index') }} 
                            </p>
                            <p class="mouse-pointer ma-0 mr-2">
                              Sequence: {{ screenContent.triggers.sequence.sequence }}
                            </p>
                          </div>
                        </template>
                        <span>Group/Sequence Trigger</span>
                      </v-tooltip>

                    </div>
                  </div>
                  <!-- Timeline-->
                  <div v-if="cardOne[screenContent.id] == 'flipped'" class="card__face card__face--back">
                    <v-list-item>
                      <v-list-item-content>
                          <v-list-item-title class="my-2 mx-1 text-center">
                              <span class="subtitle-2">Timeline Artwork #{{ screenContent.id }}</span>
                          </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider style="opacity: 0.12" />
                    <v-timeline

                      dense
                      clipped
                      class="mx-5 self-align"
                    >
                      <v-slide-x-transition group>
                        <v-timeline-item
                          v-for="event, index in screenContent.history.log.slice().reverse()"
                          :key="`schls`+index"
                          fill-dot
                          class="mb-4 dot"
                          color="grey darken-1"
                          medium
                        >
                          <template v-slot:icon>
                            <h5>{{ event.action.toUpperCase().substring(0, 3) }}</h5>
                          </template>
                          <v-card>
                            <v-card-text class="px-6 body-2">
                              <div>
                                <span><strong>Date:</strong> {{ event.dateTime.split('T')[0] }}</span>
                              </div>
                              <div>
                                <span><strong>Time:</strong> {{ event.dateTime.split('T')[1] }}</span>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-timeline-item>
                      </v-slide-x-transition>
                    </v-timeline>
                  </div>
                  <div v-if="lookuptableLinkedGroupIdBySId && lookuptableLinkedGroupIdBySId[screenContent.id] !== undefined" 
                      style="position: absolute;bottom: 0px;right: 0px;" 
                      :key="lookuptableLinkedGroupIdBySId[screenContent.id]"
                    >
                    <v-icon>mdi-link-variant</v-icon> {{lookuptableLinkedGroupIdBySId[screenContent.id]}}
                  </div>
                </v-card>

              </v-col>
            </v-row>
            <!-- No media items added yet -->
            <v-row
              v-if="filteredScreenContents === null || filteredScreenContents.length === 0"
            >
              <v-col cols="12">
                <span>No media items have been uploaded to this screen.</span>
              </v-col>
            </v-row>
            <!-- Pagination -->
            <v-row>
              <v-col
                cols="12"
                class="text-right"
              >
              <!-- v-pagionation and the length is based on the filtee -->
                <v-pagination
                  v-if="this.screenContents && Math.ceil(this.screenContents.length / this.limit)>1"
                  v-model="pageNum"
                  circle
                  :length="Math.ceil(this.filteredScreenContents.length / this.limit)"
                />
              </v-col>
            </v-row>

            <!-- Dialogs -->
            <!-- DraftPlaceholder Dialog -->
            <v-dialog
              v-model="showDraftPlaceholderDialog"
              persistent
              width="500"
            >
              <v-card>
                <v-card-title class="white--text primary">
                  Create Placeholder
                </v-card-title>
                <v-card-text>
                  <!-- Placeholder Text input -->
                  <v-row>
                    <h4>Type in placeholder description:</h4>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="draftPlacholderText"
                        outlined
                        label="Placeholder Text"
                      />
                    </v-col>
                  </v-row>

                  <!-- Create buttons -->
                  <v-row
                    justify="center"
                    class="mt-3"
                  >
                    <!-- Confirm create button -->
                    <v-btn
                      class="mx-3"
                      color="primary"
                      :disabled="placeholderCreatingFlag"
                      :loading="placeholderCreatingFlag"
                      @click="confirmCreatePlaceholder"
                    >
                      Create
                    </v-btn>

                    <!-- Decline create button -->
                    <v-btn
                      class="mx-3"
                      color="red"
                      :disabled="placeholderCreatingFlag"
                      @click="cancelCreatePlaceholder()"
                    >
                      Cancel
                    </v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- File Upload Dialog -->
            <v-dialog
              v-model="showUploadMediaDialog"
              persistent
              width="500"
            >
              <v-card>
                <v-card-title class="white--text primary">
                  Upload Media
                </v-card-title>
                <v-card-text>
                  <!-- Files selected title -->
                  <v-row>
                    <p>MP4 and Jpeg recommended</p>
                  </v-row>
                  
                  <v-row v-if="isArtworkFileBigger">
                    <v-alert type="warning">
                      Artwork exceeds recommended file size limits. Proceed with care.
                    </v-alert>
                  </v-row>
                  <v-row>
                    <h4>Files Selected:</h4>
                  </v-row>

                  <!-- Files selected -->
                  <v-row>
                    <ul>
                      <li v-for="(media, index) in uploadedFileCreative" :key="`ufc`+index">
                        <span
                          :style="{
                            color: media.err ? 'red' : 'white',
                          }"
                        >{{media.name}}</span>
                        <v-tooltip
                          content-class="top"
                          top
                        >
                          <template v-slot:activator="{ attrs, on }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="removeUploadMedia(media)"
                              small  
                              color="black"
                              class="fab-icon ml-1"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span>Remove</span>
                        </v-tooltip>
                      </li>
                    </ul>
                  </v-row>

                  <!-- Upload buttons -->
                  <v-row
                    justify="center"
                    class="mt-3"
                  >
                    <!-- Confirm upload button -->
                    <v-btn
                      class="mx-3"
                      color="primary"
                      :disabled="uploadedFileCreative === null || mediaUploadingFlag"
                      :loading="mediaUploadingFlag"
                      @click="confirmUploadToCreatives"
                    >
                      Confirm
                    </v-btn>

                    <!-- Decline upload button -->
                    <v-btn
                      class="mx-3"
                      color="red"
                      @click="cancelUploadMedia()"
                    >
                      Cancel
                    </v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- Edit Clipboard Dialog -->
            <v-dialog
              v-model="showEditClipboardDialog"
              width="500"
            >
              <v-card>
                <v-card-title class="white--text primary">
                  Edit Clipboard
                </v-card-title>
                <v-col cols="12">
                  <ul v-if="$store.state.Clipboard.triggersCopyData">
                    <div v-for="(value, key) in $store.state.Clipboard.triggersCopyData.triggers" :key="key">
                      <input type="checkbox" :id="`editClipboardCheckbox${key}`" v-model="editClipboardSelectedTriggers[key]">
                      <label :for="`editClipboardCheckbox${key}`">  {{ key }}</label>
                    </div>
                  </ul>
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    @click="saveEditClipboardTriggers"
                  >
                    Save these triggers
                  </v-btn>
                  <v-btn
                    class="mx-3"
                    color="red"
                    @click="showEditClipboardDialog=false"
                  >
                  Cancel
                </v-btn>
                </v-col>
              </v-card>
            </v-dialog>
            <!-- Social media upload dialog -->
            <v-dialog
              v-model="showUploadCreativeDialog"
              width="500"
            >
              <v-card>
                <v-card-title class="white--text primary">
                  Upload Media
                </v-card-title>
                <v-col cols="12">
                  <v-text-field
                    v-if="this.$store.state.Permissions.uploadSocial"
                    v-model="socialMediaUrl"
                    outlined
                    label="Extract Media From Twitter"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    :disabled="(socialMediaUrl === null) || mediaUploadingFlag"
                    :loading="mediaUploadingFlag"
                    @click="confirmUploadToCreatives"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-card>
            </v-dialog>
            <!-- Trigger edit dialog -->
            <v-dialog
              v-if="selectedContentForTriggerEdit && isMediaPlanner"
              content-class="editDialogCustom"
              v-model="triggerBuilderDialog"
              :persistent="dataHasChanged"
              width="800"
              @input="refreshScreens"
            >
              <v-card>
                <v-tabs
                  v-model="selectedTab"
                  fixed-tabs
                  background-color="primary"
                  show-arrows
                >
                <template v-for="(triggerForm, i) in triggerForm">
                  <v-tab 
                    v-if="(triggerForm.paramName === 'socialMedia' && !selectedContentForTriggerEdit.triggers.tweet) ? false : true" 
                    :key="`tf1`+i"
                  >
                    {{ triggerForm.friendlyName }}
                  </v-tab>
                </template>

                <v-tabs-items v-model="selectedTab">
                  <template v-for="(triggerForm, i) in triggerForm">
                    <v-tab-item :key="`tf2`+i" v-if="(triggerForm.paramName === 'socialMedia' && !selectedContentForTriggerEdit.triggers.tweet) ? false : true">
                      <div class="d-flex flex-column">
                        <!-- Trigger edit container (shorthand: display day of week horizontally and not vertical) -->
                        <div :class="(triggerForm.paramName === 'dayOfWeek') ? 'd-flex justify-center px-2' : ''">    
                          <div
                            v-for="(item, i) in triggerForm.parameters" 
                            :key="`tf3`+i"
                          >
                            <v-col
                              v-if="shouldShowOn(item.showOn, selectedContentForTriggerEdit.triggers, triggerForm.paramName)"
                              class="my-0 py-0 mt-5"
                            >
                              <div v-if="item">
                                <!-- ? -->
                                <focal-point
                                  v-if="item.component === 'FocalPoint'"
                                  :key="item.paramName"
                                  :default-val="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :selected-content="selectedContentForTriggerEdit"
                                  @coordChange="updateCoord(triggerForm.paramName, item.paramName, $event)"
                                  @ratioChange="ratio = $event"
                                />

                                <!-- Dropdown option (item.format === 'dropdown')  -->
                                <v-select
                                  :autofocus='openOnSequence'
                                  :items="item.values"
                                  v-else-if="item.format === 'dropdown'"
                                  :input-value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :key="item.label"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event)"
                                  hide-details
                                ></v-select>

                                <!-- Dropdown Ladbrokes Options (Single) (item.format === 'dropdownLadbrokes')  -->
                                <v-autocomplete
                                  :autofocus='openOnSequence'
                                  v-else-if="item.format == 'dropdownLadbrokes'"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event)"
                                  hide-details
                                  outlined
                                  :items="ladbrokesInfo[item.paramName]"
                                  :item-text="(item) => item.name + ' - (' + item.id + ')'  + (item.extra != '' ? ' - ' + item.extra : '')"
                                  :item-value="(item) => '('+item.id+')'"
                                ></v-autocomplete>

                                <!-- Dropdown Ladbrokes Options (Multiple) (item.format === 'dropdownLadbrokesMultiple')  -->
                                <v-autocomplete
                                  :autofocus='openOnSequence'
                                  v-else-if="item.format == 'dropdownLadbrokesMultiple'"
                                  :value="safeListSplit(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName],'|')"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join('|'))"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event.join('|'))"
                                  hide-details
                                  outlined
                                  multiple
                                  :items="ladbrokesInfo[item.paramName]"
                                  :item-text="(item) => item.name + ' - (' + item.id + ')'  + (item.extra != '' ? ' - ' + item.extra : '')"
                                  :item-value="(item) => '('+item.id+')'"
                                ></v-autocomplete>

                                <!-- Dropdown Multiple option (item.format === 'dropdownMultiple')  -->
                                <v-select
                                  :autofocus='openOnSequence'
                                  :items="item.values"
                                  v-else-if="item.format === 'dropdownMultiple'"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName] ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',') : []"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join(','))"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event.join(','))"
                                  multiple
                                ></v-select>

                                <!-- Dropdown Multiple option (item.format === 'locationListDropdownMultiple')  -->
                                <v-combobox
                                  :autofocus='openOnSequence'
                                  :items="regions"
                                  v-else-if="item.format === 'locationListDropdownMultiple'"
                                  v-model="regionsSearchText[item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, regionsSearchText[item.paramName].join(','))"
                                  @input="updateTrigger(triggerForm.paramName, item.paramName, regionsSearchText[item.paramName].join(','))"
                                  hide-details
                                  multiple
                                  item-text="value"
                                  small-chips
                                  style="width: 100%"
                                  append-icon="mdi-plus"
                                  @keypress.tab.prevent.native="stopKey"
                                  @keydown.tab.prevent.native="stopKey"
                                  @keyup.tab.prevent.native="stopKey"
                                  @keydown.enter.prevent.native="submitSearch(triggerForm.paramName, item.paramName, i, $event)"
                                  @keyup="stopKey"
                                  @keypress="stopKey"
                                  :search-input.sync="searchInputs[i]"
                                  :return-object="false"
                                  :key="`lldm_${item.paramName}_${i}`"
                                >
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                No matching region "<strong>{{ searchInputs[i] }}</strong>". Click <kbd>+</kbd> to create a new one
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-slot:selection="{ attrs, item, parent, selected }" >
                                    <v-chip
                                        v-bind="attrs"
                                        :style="'background: none; border: 1px solid #fff'"
                                        :input-value="selected"
                                        label
                                        small
                                    >
                                        <span class="pr-2">
                                            {{ item }}
                                        </span>
                                        <v-icon
                                            small
                                            @click="parent.selectItem(item)"
                                        >
                                            mdi-close
                                        </v-icon>
                                    </v-chip>
                                </template>
                              </v-combobox>

                                <!-- Dropdown Movies (item.format === 'dropdownMovies')  -->
                                <v-autocomplete
                                  :autofocus='openOnSequence'
                                  v-else-if="item.format === 'dropdownMovies'"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, '[' + $event.id + '] - ' + $event.title)"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, '[' + $event.id + '] - ' + $event.title)"
                                  hide-details
                                  outlined
                                  return-object
                                  :items="internationalShowtimeMovies"
                                  :item-text="(item) => '[' + item.id + '] - ' + item.title"
                                ></v-autocomplete>

                                <!-- Dropdown Stations (item.format === 'dropdownStations')  -->
                                <v-autocomplete
                                  :autofocus='openOnSequence'
                                  v-else-if="item.format === 'dropdownStations'"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName] ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split('|') : []"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  multiple
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join('|'))"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event.join('|'))"
                                  hide-details
                                  outlined
                                  :items="nationalRailStations"
                                  :item-text="(item) => item.stationName + ' - (' + item.crsCode + ')'"
                                ></v-autocomplete>

                                <!-- Dropdown Stations (item.format === 'dropdownOperators')  -->
                                <v-autocomplete
                                  :autofocus='openOnSequence'
                                  v-else-if="item.format === 'dropdownRailOperators'"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName] ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split('|') : []"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  multiple
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join('|'))"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event.join('|'))"
                                  hide-details
                                  outlined
                                  :items="nationalRailOperators"
                                  :item-text="(item) => item.operatorName + ' - (' + item.code + ')'"
                                ></v-autocomplete>

                                <!-- Dropdown Airports (item.format === 'dropdownAirports')  -->
                                <v-autocomplete
                                  :autofocus='openOnSequence'
                                  v-else-if="item.format === 'dropdownAirports'"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName] ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split('|') : []"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  multiple
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join('|'))"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event.join('|'))"
                                  hide-details
                                  outlined
                                  :items="iataAirports"
                                  :item-text="(item) => item.airportCity + ' - (' + item.iataIdentifier + ') - ' + item.languages"
                                  :item-value="(item) => item.airportCity + ' - (' + item.iataIdentifier + ')'"
                                ></v-autocomplete>

                                <!-- Dropdown FlightAPI Airports (item.format === 'dropdownFlightAPIAirports')  -->
                                <v-autocomplete
                                  :autofocus='openOnSequence'
                                  v-else-if="item.format === 'dropdownFlightAPIAirports'"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName] ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split('|') : []"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  multiple
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join('|'))"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event.join('|'))"
                                  hide-details
                                  outlined
                                  :items="airports"
                                  :item-text="(item) => item.airportCity + ' - (' + item.iataIdentifier + ')'"
                                  :item-value="(item) => item.airportCity + ' - (' + item.iataIdentifier + ')'"
                                ></v-autocomplete>

                                <!-- Dropdown Airlines (item.format === 'dropdownAirlineOperators')  -->
                                <v-autocomplete
                                  :autofocus='openOnSequence'
                                  v-else-if="item.format === 'dropdownAirlineOperators'"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName] ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split('|') : []"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  multiple
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join('|'))"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event.join('|'))"
                                  hide-details
                                  outlined
                                  :items="airlines"
                                  :item-text="(item) => item.airportCity + ' - (' + item.iataIdentifier + ')'"
                                  :item-value="(item) => item.airportCity + ' - (' + item.iataIdentifier + ')'"
                                ></v-autocomplete>

                                
                                <!-- Dropdown Rugby Teams (item.format === 'dropdownRugbyTeams')  -->
                                <v-autocomplete
                                  :autofocus='openOnSequence'
                                  v-else-if="item.format === 'dropdownRugbyTeams'"
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName] ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split('|') : []"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  multiple
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join('|'))"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event.join('|'))"
                                  hide-details
                                  outlined
                                  :items="rugbyTeams"
                                  :item-text="(item) => item.teamName"
                                  :item-value="(item) => item.teamName"
                                ></v-autocomplete>

                                <!-- Dropdown option (item.format === 'publishStateSelector')  -->
                                <v-select
                                  :autofocus='openOnSequence'
                                  :items="item.values"
                                  v-else-if="item.format === 'publishStateSelector'"
                                  :input-value="publishStateConvert(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName])"
                                  :key="item.friendlyName"
                                  :value="publishStateConvert(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName])"
                                  :v-model="publishStateConvert(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName])"
                                  :label="item.friendlyName"
                                  :outlined="true"
                                  :type="item.type"
                                  @change="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                                  @input="updateTrigger(triggerForm.paramName,item.paramName, $event)"
                                  hide-details
                                ></v-select>

                                <!--*************** Dropdown option (item.format === 'colourPicker')**********  -->
                                
    <div v-else-if="item.format === 'colourPicker'">
    <small v-html="item.friendlyName" v-if="item.friendlyName" class=" ml-3 grey--text"/>  
    <div class="color-picker" style="width: 98%">
     
      <v-btn
        v-for="(colour, index) in colours"
        :key="`cp`+index"
        @click="updateTrigger(triggerForm.paramName, item.paramName, colour[0])"
        :class="{ 'selected-color': isSelectedColor(triggerForm.paramName, item.paramName, colour[0]) }"
        class="color-btn"
        style="min-width: 5px;"
      >
      {{index}}                           
        <v-icon :color="colour[1]">mdi-square</v-icon>
      </v-btn>

    </div>
  </div>

                                
 <!--*******************************************************************************************************************-->
                                <!-- HTML Preview (item.format === 'htmlPreview')  -->
                                <div
                                  class="pb-5"
                                  v-else-if="item.format === 'htmlPreview'"
                                >
                                  <div
                                    ref="iFrameContainer"
                                    :style="{
                                      width: previewWidth*(previewScale/100) + 'px',
                                      height: previewHeight*(previewScale/100) + 'px',
                                      transform: 'scale('+ previewScale/100 +')',
                                      transformOrigin: '0 0',
                                    }"
                                  >
                                    <iframe
                                      ref="iFrameView"
                                      :style="{
                                        width: (100*(100/previewScale))+'%',
                                        height: (100*(100/previewScale))+'%',
                                        border: 'none',
                                      }"
                                      :src="previewUrl"
                                      allow="autoplay"
                                      id="htmlPreviewIframe"
                                      onLoad="this.name = Date.now();"
                                    >
                                    </iframe>
                                  </div>
                                </div>

                                <!-- Dual Single Slider (item.format === 'singleSlider')  -->
                                <div
                                  v-else-if="item.format === 'singleSlider'"
                                  class="ma-0 pa-0"
                                >
                                  <label class="ma-0 pa-0">{{item.friendlyName}}</label>
                                  <v-slider
                                    color="info"
                                    track-color="info lighten-4"
                                    :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                    :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                    @change="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                                    @input="updateTrigger(triggerForm.paramName,item.paramName, $event)"
                                    class="ma-0 pa-0"
                                    :min="getRule('min',item.rules)"
                                    :max="getRule('max',item.rules)"
                                    step="0.1"
                                    hide-details
                                  >
                                  <template v-slot:prepend>
                                      <input
                                        :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                        class="ma-0 pa-0"
                                        hide-details
                                        flat solo
                                        type="number"
                                        style="width: 80px"
                                        :min="getRule('min',item.rules)"
                                        :max="getRule('max',item.rules)"
                                        @input="updateTrigger(triggerForm.paramName,item.paramName, $event.target.value)"
                                      />
                                  </template>
                                  <template v-slot:append>
                                      <input
                                        :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                        class="ma-0 pa-0"
                                        hide-details
                                        flat solo
                                        type="number"
                                        style="width: 80px"
                                        :min="getRule('min',item.rules)"
                                        :max="getRule('max',item.rules)"
                                        @input="updateTrigger(triggerForm.paramName,item.paramName, $event.target.value)"
                                      />
                                  </template>
                                </v-slider>
                                </div>

                                <!-- Dual Range Slider (item.format === 'dualSlider')  -->
                                <div
                                  v-else-if="item.format === 'dualSlider'"
                                  class="ma-0 pa-0"
                                >
                                  <label class="ma-0 pa-0">{{item.friendlyName}}</label>
                                  <v-range-slider
                                    color="info"
                                    track-color="info lighten-4"
                                    :value="(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',').map(Number) : item.defaultValue.split(',').map(Number)"
                                    :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                    @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join(','))"
                                    @input="updateTrigger(triggerForm.paramName,item.paramName, $event.join(','))"
                                    class="ma-0 pa-0"
                                    :min="getRule('min',item.rules)"
                                    :max="getRule('max',item.rules)"
                                    step="0.1"
                                    hide-details
                                  >
                                  <template v-slot:prepend>
                                      <input
                                        :value="(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',').map(Number)[0] : item.defaultValue.split(',').map(Number)"
                                        class="ma-0 pa-0"
                                        hide-details
                                        flat solo
                                        type="number"
                                        style="width: 80px"
                                        :min="getRule('min',item.rules)"
                                        :max="getRule('max',item.rules)"
                                        @input="updateTriggerStrArr(triggerForm.paramName,item.paramName, $event.target.value, 0)"
                                      />
                                  </template>
                                  <template v-slot:append>
                                      <input
                                        :value="(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',').map(Number)[1] : item.defaultValue.split(',').map(Number)"
                                        class="ma-0 pa-0"
                                        hide-details
                                        flat solo
                                        type="number"
                                        style="width: 80px"
                                        :min="getRule('min',item.rules)"
                                        :max="getRule('max',item.rules)"
                                        @input="updateTriggerStrArr(triggerForm.paramName,item.paramName, $event.target.value, 1)"
                                      />
                                  </template>
                                </v-range-slider>
                                </div>

                                <!-- Date picker option (item.format === 'date') -->
                                <div
                                  v-else-if="item.format === 'date'"
                                >
                                  <v-menu
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                        :label="item.friendlyName"
                                        outlined
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        v-bind="attrs"
                                        hide-details
                                        clearable
                                        @click:clear="selectedDate = null; updateTrigger(triggerForm.paramName, item.paramName, selectedDate)"
                                        @click="updateSelectedDate(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName])"
                                      >
                                      </v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="selectedDate"
                                      @input="updateTrigger(triggerForm.paramName, item.paramName, selectedDate)"
                                      no-title
                                      scrollable
                                    />
                                  </v-menu>
                                </div>

                                <!-- Dual Single Slider (item.format === 'singleSlider')  -->
                          <div v-else-if="item.format === 'singleSlider'" class="ma-0 pa-0">
                            <label class="ma-0 pa-0">{{ item.friendlyName }}</label>
                            <v-slider color="info" track-color="info lighten-4"
                              :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                              :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                              @change="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                              @input="updateTrigger(triggerForm.paramName, item.paramName, $event)" class="ma-0 pa-0"
                              :min="getRule('min', item.rules)" :max="getRule('max', item.rules)" step="0.1" hide-details dense>
                              <template v-slot:prepend>
                                <input
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  class="ma-0 pa-0" hide-details flat solo type="number" style="width: 80px"
                                  :min="getRule('min', item.rules)" :max="getRule('max', item.rules)"
                                  @input="updateTrigger(triggerForm.paramName, item.paramName, $event.target.value)" />
                              </template>
                              <template v-slot:append>
                                <input
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  class="ma-0 pa-0" hide-details flat solo type="number" style="width: 80px"
                                  :min="getRule('min', item.rules)" :max="getRule('max', item.rules)"
                                  @input="updateTrigger(triggerForm.paramName, item.paramName, $event.target.value)" />
                              </template>
                            </v-slider>
                          </div>

                          <!-- Dual Range Slider (item.format === 'dualSlider')  -->
                          <div v-else-if="item.format === 'dualSlider'" class="ma-0 pa-0">
                            <label class="ma-0 pa-0">{{ item.friendlyName }}</label>
                            <v-range-slider color="info" track-color="info lighten-4"
                              :value="(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',').map(Number) : item.defaultValue.split(',').map(Number)"
                              :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                              @change="updateTrigger(triggerForm.paramName, item.paramName, $event.join(','))"
                              @input="updateTrigger(triggerForm.paramName, item.paramName, $event.join(','))"
                              class="ma-0 pa-0" :min="getRule('min', item.rules)" :max="getRule('max', item.rules)"
                              step="0.1" hide-details dense>
                              <template v-slot:prepend>
                                <input
                                  :value="(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',').map(Number)[0] : item.defaultValue.split(',').map(Number)"
                                  class="ma-0 pa-0" hide-details flat solo type="number" style="width: 80px"
                                  :min="getRule('min', item.rules)" :max="getRule('max', item.rules)"
                                  @input="updateTriggerStrArr(triggerForm.paramName, item.paramName, $event.target.value, 0)" />
                              </template>
                              <template v-slot:append>
                                <input
                                  :value="(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',').map(Number)[1] : item.defaultValue.split(',').map(Number)"
                                  class="ma-0 pa-0" hide-details flat solo type="number" style="width: 80px"
                                  :min="getRule('min', item.rules)" :max="getRule('max', item.rules)"
                                  @input="updateTriggerStrArr(triggerForm.paramName, item.paramName, $event.target.value, 1)" />
                              </template>
                            </v-range-slider>
                          </div>


                          <!-- Date Range Slider (item.format === 'dateRange')  -->
                          <div v-else-if="item.format === 'dateRange'" class="ma-0 pa-0">
                            <v-row>
                              <v-col cols=12 style="margin-bottom: -12px">
                                <div>
                                  <div style="
                                        font-size: 11px;
                                        margin-left: 10px;
                                        background: #1e1e1e;
                                        width: 110px;
                                        padding: 2px;
                                        height: 22px;
                                        position: absolute;
                                        top: 0px;
                                        z-index: 1;
                                    ">{{ item.friendlyName }}</div>
                                <date-range-picker ref="picker"
                                  :locale-data="{ firstDay: 0, format: 'yyyy-mm-dd HH:MM', separator: ' - ' }"
                                  :dateRange="toDateRange(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName])"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :single-date-picker=false :time-picker=true :auto-apply=false :append-to-body=true
                                  :ranges=false opens="left"
                                  @update="updateTrigger(triggerForm.paramName, item.paramName, selectedContentForTriggerEdit.dateRangeIndefinite ? '|' : toTriggerDateRange($event))"
                                  @select="handleCalendarSelect"
                                  @start-selection="handleCalendarSelect"
                                  @finish-selection="handleCalendarSelect"
                                  @toggle="handleCalendarToggle" dense>
                                  <template v-slot:input="picker" style="min-width: 350px; background-color: black;">
                                    {{ selectedContentForTriggerEdit.dateRangeIndefinite ? 'Indefinite' : calendarDateRange(picker.startDate) + ' - ' + calendarDateRange(picker.endDate) }}
                                  </template>
                                  <!-- -->
                                  <template v-slot:footer="picker">

                                    <div class="drp-buttons" style="
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                    ">
                                        <div style="
                                            width: 20%;
                                            margin-left: -16px;
                                        ">
                                            <input 
                                                type="checkbox" 
                                                @click="setIndefinite(picker)" 
                                                :checked="selectedContentForTriggerEdit.dateRangeIndefinite ? true : false">
                                                Indefinite?
                                            </input>
                                        </div>
                                        <span class="drp-selected" style="
                                            flex-grow: 1;
                                            text-align: center;
                                        ">{{ picker.rangeText }}</span>
                                        <div style="width: 20%; display: flex; justify-content: flex-end;">
                                            <v-btn @click="handleCalendarCancel(picker)" elevation="0"  color="var(--v-sidebarColorLight-lighten2)" style="margin-right: 8px;" small>Cancel</v-btn>
                                            <v-btn @click="handleCalendarApply(picker)" elevation="0" color="var(--v-secondary-base)" small>Apply</v-btn>
                                        </div>
                                    </div>

                                  </template>
                                </date-range-picker>
                                </div>
                              </v-col>
                            </v-row>
                          </div>

                                <!-- Dynamically inject other formats -->
                                <div v-else>
                                  <validation-provider 
                                    v-slot="{ errors }"
                                    :rules="item.rules"
                                  >
                                    <component
                                      :ref="item.paramName"
                                      :key="item.label"
                                      :is="item.component"
                                      :type="item.type"
                                      :input-value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                      :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                      :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                    
                                      @paste="checkTriggerRules($event, item)"
                                      @keyup="disableSaveTriggerBtn(errors[0], item.paramName)"
                                      @keypress="checkTriggerRules($event, item)"
                                      @input="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                                      @change="updateTrigger(triggerForm.paramName, item.paramName, $event)"

                                      hide-details
                                      :true-value="true" :false-value="false"
                                      :disabled="item.format === 'Warning'"
                                      :label="item.friendlyName"
                                      :outlined="true"
                                      :color="(item.format === 'CheckBoxTrafficLight') ? (isTrue(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? '#98341f' : '#00FF00') : ''"
                                    />
                                    <div class="mt-1 ml-1 red--text">
                                      {{ triggerErrorMessage(errors[0], item.paramName) }}
                                    </div>
                                  </validation-provider>
                                </div>
                              </div>
                              <small v-html="item.comments" v-if="item.comments" class=" ml-1 grey--text"/>
                            </v-col>
                          </div>
                        </div>
                      
                        <!-- Save trigger -->
                        <v-col
                          cols="12"
                          class="text-right"
                        >
                          <v-btn
                            :disabled="disableTriggerSaveBtn || !dataHasChanged"
                            :loading="disableTriggerSave"
                            @click="submitTriggers()"
                            color="primary"
                            class="mx-1"
                          >
                            Save
                          </v-btn>
                          <v-btn
                            @click="resetTriggers(); triggerBuilderDialog = false;"
                            color="red"
                            class="mx-1"
                          >
                            Cancel
                          </v-btn>
                        </v-col>
                      </div>
                    </v-tab-item>
                  </template>
                </v-tabs-items>
                </v-tabs>
              </v-card>
            </v-dialog>

            <!-- Profanity dialog -->
            <v-dialog
              v-model="profanityDoubleCheckDialog"
              width="600"
            >
              <v-card class="pa-4">
                <span
                  class="h4"
                >Please uncheck 'use on social media grid' or approve profanity before
                  publishing.</span>
              </v-card>
            </v-dialog>
            <!-- Delete content dialog -->
            <v-dialog
              v-if="selectedContentForTriggerEdit"
              v-model="deleteContentDialog"
              width="600"
            py-2>
              <v-card class="px-6 py-3">
                Are you sure you want to delete
                {{ selectedContentForTriggerEdit.media.originalFileName }}?
                <v-card-actions>
                  <v-col
                    cols="12"
                    class="text-right ma-0 pa-0"
                  >
                    <v-btn
                      color="primary"
                      class="mr-3"
                      @click="deleteContentFromScreen"
                    >
                      Confirm
                    </v-btn>
                    <v-btn
                      color="red"
                      @click="deleteContentDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Full screen preview dialog -->
            <v-dialog
              v-model="fullscreenPreviewDialog"
              width="600"
            >
              <v-card>
                <v-row>
                  <v-col
                    cols="12"
                    class="text-left"
                  >
                    <v-btn
                      color="primary"
                      icon
                      :href="this.fullsizePreviewImgUrl"
                      target="blank"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-img
                      contain
                      :src="fullsizePreviewImgUrl"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
            <!-- Copy Triggers to Linked Content Dialog -->
            <v-dialog
              v-model="copyLinkedTriggerDialog"
              width="600"
            py-2>
              <v-card class="px-6 py-3">
                Do you want to apply these changes to the linked content?
                <v-card-actions>
                  <v-col
                    cols="12"
                    class="text-right ma-0 pa-0"
                  >
                    <v-btn
                      color="primary"
                      class="mr-3"
                      @click="synchronizeLinkedContentTriggers"
                    >
                      Confirm
                    </v-btn>
                    <v-btn
                      color="red"
                      @click="copyLinkedTriggerDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Delete Linked Content Dialog -->
            <v-dialog
              v-model="deleteLinkedContentDialog"
              width="600"
            py-2>
              <v-card class="px-6 py-3">
                Do you want to delete the linked content?
                <v-card-actions>
                  <v-col
                    cols="12"
                    class="text-right ma-0 pa-0"
                  >
                    <v-btn
                      color="primary"
                      class="mr-3"
                      @click="deleteLinkedContent"
                    >
                      Confirm
                    </v-btn>
                    <v-btn
                      color="red"
                      @click="deleteLinkedContentDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-tab-item>

        <!-- Locations -->
        <v-tab-item 
          style="background: transparent"
        >
          <locations
            v-if="$store.state.Permissions.locationView && selectedBurst && selectedScreen && tab === 2"
            :selectedBurst="selectedBurst"
            :selectedScreen="selectedScreen"
            :selectedCampaignBurstScreen="selectedCampaignBurstScreen"
            :previewLocation="previewLocation"
            @setPreviewLocation="setPreviewLocation"
            :setPreviewLocation="setPreviewLocation"
          />
        </v-tab-item>

        <!-- Airport -->
        <v-tab-item
          style="background: transparent"
        >
          <airport
            v-if="$store.state.Permissions.airportView && selectedBurst && selectedScreen && tab === 3"
            :selectedBurst="selectedBurst"
            :selectedScreen="selectedScreen"
            :selectedCampaignBurstScreen="selectedCampaignBurstScreen"
            :campaign="campaign"
          />
        </v-tab-item>

        <!-- Preview -->
        <v-tab-item
          style="background: transparent"
        >
          <preview
            v-if="$store.state.Permissions.previewView && selectedBurst && selectedScreen && tab === 4"
            :selectedBurst="selectedBurst"
            :selectedScreen="selectedScreen"
            :selectedCampaignBurstScreen="selectedCampaignBurstScreen"
            :screenLevelTriggers="screenLevelTriggers"
            :locationsList="locationsList"
            :previewLocation="previewLocation"
            @setPreviewLocation="setPreviewLocation"
            :setPreviewLocation="setPreviewLocation"
          />
        </v-tab-item>

        <!-- Resources -->
        <v-tab-item
          style="background: transparent"
        >
          <resources
            v-if="($store.state.Permissions.resourcesView || $store.state.Permissions.resourcesRestrictedView) && selectedBurst && selectedScreen && tab === 5"
            mode="resources" 
            :selectedBurst="selectedBurst"
            :selectedScreen="selectedScreen"
            :selectedCampaignBurstScreen="selectedCampaignBurstScreen"
          />
        </v-tab-item>

        <!-- Settings -->
        <v-tab-item
          style="background: transparent"
        >
          <resources
            v-if="$store.state.Permissions.settingsView && selectedBurst && selectedScreen && tab === 6"
            mode="settings" 
            :selectedBurst="selectedBurst"
            :selectedScreen="selectedScreen"
            :selectedCampaignBurstScreen="selectedCampaignBurstScreen"
          />
        </v-tab-item>

        <!-- Screen Specs -->
        <v-tab-item
          style="background: transparent"
        >
          <ScreenSpecs 
            v-if="$store.state.Permissions.screenSpecsView && tab === 7"
            :selectedScreen="selectedScreen"
          />
        </v-tab-item>
      </v-tabs-items>
  </v-container>
</template>
<script>
  /** Components */
  import Locations from '@/components/artwork/Locations.vue'
  import Airport from '@/components/artwork/Airport'
  import Preview from '@/components/artwork/Preview'
  import Resources from '@/components/artwork/Resources.vue'
  import ScreenSpecs from '@/components/artwork/ScreenSpecs.vue'
  import FocalPoint from '@/components/triggers/FocalPoint'
  import BurstSelect from '../../../../../components/artwork/BurstSelect.vue'
  import Cards from '../../../../../components/artwork/Cards.vue'
  import Crumbs from '../../../../../components/artwork/Crumbs.vue'
  import IndexLocations from '../../../../../components/artwork/IndexLocations.vue'
  import PageLimit from '../../../../../components/artwork/PageLimit.vue'
  import Placeholder from '../../../../../components/artwork/Placeholder.vue'
  import ScreenSelect from '../../../../../components/artwork/ScreenSelect.vue'
  import Social from '../../../../../components/artwork/Social.vue'
  import Tabs from '../../../../../components/artwork/Tabs.vue'
  import Upload from '../../../../../components/artwork/Upload.vue'
  import XmlFilters from '../../../../../components/artwork/XmlFilters.vue'
  import SOVSlider from '@/components/campaign/SOVSlider'
  // LBC
  import UploadMediaTabLBC from '../../../../../components/artwork/lbc/uploadMedia/index.vue'
  /** Controllers */  
  import CampaignController from '@/services/controllers/Campaign'
  import MediaTypeController from '@/services/controllers/MediaType'
  import BespokeController from '@/services/controllers/Bespoke'
  /** Additional packages */
  import moment from 'moment'
  import axios from 'axios'
  import { debounce } from 'lodash';
  import DateRangePicker from 'vue2-daterange-picker';
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

  const triggersList = require(`@/assets/${process.env.VUE_APP_TRIGGERS_VERSION}`)
  import { VTextField, VCheckbox,VTextarea } from 'vuetify/lib'
  
  export default {
    components: {
      VTextarea,
      VTextField,
      FocalPoint,
      VCheckbox,
      Locations,
      Airport,
      Preview,
      Resources,
      ScreenSpecs,
      BurstSelect,
      Cards,
      Crumbs,
      IndexLocations,
      PageLimit,
      Placeholder,
      ScreenSelect,
      Social,
      Tabs,
      Upload,
      XmlFilters,
      UploadMediaTabLBC,
      SOVSlider,
      DateRangePicker
    },

    filters: {
      momentHuman: function (date) {
        return moment(date).format('DD MMM YYYY hh:mm a')
      },
      momentUnix: function (date) {
        return moment.unix(date).format('DD MMM YYYY hh:mm a')
      },
      momentScreenMonth: function (date, type) {
        if(date) {
          return moment(date).format('MMM D')
        }
        else {
          return(type === 's') ? 'Start' : (type === 'e') ? 'End' : ''
        }
      },
    },

    data() {
      return {
      searchInputs: [],
      regionsSearchText: { regions: [], antiRegions: []},
      dateRangeFormat: 'YYYY-MM-DD HH:mm',
      creatorEditorTriggers: ['globalLBCnews'],
      selectedArtWorkFilters: null,
      submittingTrigger: false,
      dataHasChanged: false,
      previewLocation: {},
      locationsList: [],
      disableTriggerSaveBtn: false,
      showUploadMediaDialog: false,
      showDraftPlaceholderDialog: false,
      showEditClipboardDialog: false,
      editClipboardSelectedTriggers: {},
      draftPlacholderText: "",
      selectedDate: null,
      campaign: null,
      selectedBurst: null,
      campaignBursts: null,
      selectedCampaignBurstScreen: null,
      campaignBurstScreens: null,
      // Tabs
      tabOptions: ["creator", "uploadMedia", "locations", "airport", "preview", "resources", "settings" , "screenSpecs"],
      tab: 1,
      checkedTabOnLoad: false, 
      selectedTab: null,
      // Edit triggers
      disableTriggerSave: false,
      profanityDoubleCheckDialog: false,
      limit: 10,
      pageLimitOptions: [10, 20, 50],
      selectedTriggerFilter: [],
      availableTriggerFilters: [
        {
          text: 'Twitter',
          val: 'tweet',
        },
        {
          text: 'Instagram',
          val: 'instagram',
        },
        {
          text: 'Hero',
          val: 'hero',
        },
        {
          text: 'Highlight',
          val: 'highlight',
        },
      ],
      lastUpdatedTime: null,
      selectedScreen: null,
      socialMediaUrl: null,
      uploadedFileCreative: [],
      showUploadCreativeDialog: false,
      copyLinkedTriggerDialog: false,
      lookuptableLinkedGroupIdBySId: {},
      lookuptableLinkedGroupIdBySIdUpdate: 0,
      deleteLinkedContentDialog: false,
      selectedContentLinkedFormats: null,
      linkedContentForCopy: null,
      mediaUploadingFlag: false,
      placeholderCreatingFlag: false,
      deleteContentDialog: false,
      screenLevelTriggers: null,
      screenContents: null,
      showFilterBar: false,
      screen: null,
      pageNum: 1,
      triggerBuilderDialog: false,
      selectedContentForTriggerEdit: null,
      selectedTriggerType: null,
      triggerJson: triggersList,
      placeholderContentTrigger: {},
      triggerForm: [],
      fullscreenPreviewDialog: false,
      fullsizePreviewImgUrl: null,
      ratio: null,
      breadcrumbItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/#/dashboard/campaigns',
        },
        {
          text: 'Dashboard',
          disabled: false,
        },
        {
          text: 'Burst',
          disabled: false,
        },
        {
          text: 'Uploaded Media',
          disabled: true,
        },
      ],
      openOnSequence: false,
      triggerSaveQueue: [],
      originalTriggerValues: null,
      internationalShowtimeMovies: {},
      nationalRailStations: null,
      nationalRailOperators: null,
      iataAirports: null,
      airports: null,
      airlines: null,
      rugbyTeams: [],
      ladbrokesInfo: {},
      watchesTracker: [],
      regionExpand: false,
      // Burst info
      burstDeliveryType: null,
      // Replace artwork
      artworkReplace: false,
      selectedArtToRepl: null,
      // Filters
      showAdvancedFilters: false,
      // Advanced Filters
      selectedGoLiveValue: [],
      goLiveItems: ['Archived', 'Draft', 'Preview', 'Published'],
      selectedDayOfWeekValue: [],
      dayOfWeekItems: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      selectedDateRangeValue: null,
      dateRangeItems: ['Today', 'Yesterday', 'Last 7 days', 'Last 30 days', 'This month', 'Last month', 'This year', 'Last year'],
      selectedTimeRangeValue: null,
      timeRangeItems: ['Last 24 hours', 'Last 48 hours', 'Last 7 days', 'Last 30 days', 'Last 60 days', 'Last 90 days'],
      selectedSortValue: {text: 'Group', value: 'group'},
      selectedRegionFilters: [],
      calendarTrigger: false,
      calendarText: '',
      calendarValue: null,
      startTimeValue: null,
      endTimeValue: null,
      // Timeline
      cardOne: {},
      // Size Check
      isArtworkFileBigger: false,
      // Loaders
      loadingScreens: false,
      //Sequence Colors
  
      colours: [["[0] None","#808080","#FFF"],["[1] Red","#FF0000","#FFF"],["[2] Orange","#FFA500","#FFF"],["[3] Yellow","#FFFF00","#000"],["[4] Green","#008000","#FFF"],["[5] Blue","#0000FF","#FFF"],["[6] Indigo","#4B0082","#FFF"],["[7] Violet","#8F00FF","#FFF"], ["[8] Vermilion","#FF4D00","#FFF"], ["[9] Amber","#FFBF00","#FFF"], ["[10] Chartreuse","#7FFF00","#000"], ["[11] Teal","#008080","#FFF"], ["[12] Cyan","#00FFFF","#000"], ["[13] Turquoise","#40E0D0","#000"], ["[14] Azure","#007FFF","#FFF"], ["[15] Magenta","#FF00FF","#FFF"]],
      previewUrl: null,
      previewWidth: 1080,
      previewHeight: 1920,
      previewScale: 25,
      previewTime: moment().format('HH:mm'),
      previewDate: moment().format('YYYY-MM-DD'),
      previewPan: true,
      sliderGroups: []
    }},    
    async created() {
      // Get all medias in campaign burst screen
      await this.getCampaignById()
      //await this.getAllLocations()
      await this.checkForTabSelection()

      if(this.allowCreator()) {
        // only take first 2 breadcrumbs
        this.breadcrumbItems = this.breadcrumbItems.slice(0, 2)
      }
    },

    updated() {
      this.updateTabSelection()
      const reload = localStorage.getItem("upload_media_reload")
      if(reload && reload === "true") {
        this.refreshScreens();
        localStorage.setItem("upload_media_reload", "false");
      }
    },
    
    computed: {
      regions() {
          let defaults = ['All Regions', 'default']
          let assignedRegions = this.screenContents.map((item) => {
                if(item.triggers.region)
                  return item.triggers.region.regions.split(',')
              }).flat()
          let locationRegions = this.locationsList.map((item) => {
                if(item.region)
                  return item.region.split(',')
              }).flat()
          // return both ordered
          let joinedList =  [...new Set([...assignedRegions, ...locationRegions])].sort()
          let outputList = [...new Set([...defaults, ...joinedList])]
          // sort output list, make distinct and remove blanks/undefined (Set above makes it distinct)
          outputList = outputList.filter((item) => item !== undefined && item !== '')
          return outputList
        },

      // Permissions
      isMediaPlanner() {
        return this.$store.state.Permissions.uploadMediaView && this.$store.state.Permissions.uploadMedia
      },
      showUploadSocial() {
        return (this.$store.state.Permissions.uploadSocial) ? true : false
      },
      userRole() {
        return this.$store?.state?.User?.user?.roleName?.toLowerCase()
      },
      
      sortedCampaignBurstScreens() {
        let arr = this.campaignBurstScreens
        let clone =  (arr && arr.length > 0) ? [...arr] : []
       
        clone.sort((a, b) => 
          a.screen.mediaOwner.name.localeCompare(b.screen.mediaOwner.friendlyName) ||
          a.screen.name.localeCompare(b.screen.name)
        )
        return clone
      },

      allowTriggersEdit() {
        return this.$store.state.Permissions.triggersEdit
      },

      allowScreenMediaRemove() {
        return this.$store.state.Permissions.screenMediaRemove
      },

      showMediaUpload() {
        return (this.$store.state.Permissions.uploadMedia && this.$store.state.Permissions.uploadSocial) ? true : false
      },
      // Check if trigger is present in screen level triggers
      isGoLive() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'golive' })
      },
      isDayOfWeek() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'dayOfWeek' })
      },
      isRegion() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'region' })
      },
      isGroupSequence() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'sequence' })
      },
      isCalendar() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'calendar' })
      },
      isTime() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'time' })
      },
      // make filteredScreenContents function better and check for correctness
      filteredScreenContents() {
        // Check if originalScreenContents is not empty
        if (!this.screenContents || this.screenContents.length === 0) {
          return [];
        }
        let filteredScreenContents = this.screenContents;
        // Filter originalScreenContents based on selectedGoLiveValue
        if (this.selectedGoLiveValue && this.selectedGoLiveValue.length > 0 && this.isGoLive !== undefined) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let goLive = this.getGoLivePublishState(content.triggers.golive.islive);
            return this.selectedGoLiveValue.some(function(filter) { return goLive === filter });
          }, this);
        }
        
        // Filter originalScreenContents based on mutiple selectedDayOfWeekValue
        if (this.selectedDayOfWeekValue && this.selectedDayOfWeekValue.length > 0 && this.isDayOfWeek !== undefined) {
          // Bind the isTrue function to the correct this context
          const isTrue = this.isTrue.bind(this);
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let dayOfWeek = content.triggers.dayOfWeek;
            return this.selectedDayOfWeekValue.some(function(filter) { return isTrue(dayOfWeek[filter.toLowerCase()]) });
          }, this);
        }
        // Sort filteredScreenContents based on selectedSortValue
        if (this.selectedSortValue.value === 'asc') {
          filteredScreenContents = filteredScreenContents.sort(function(a, b) {
            return new Date(a.history.log[a.history.log.length-1].dateTime) - new Date(b.history.log[b.history.log.length-1].dateTime);
          });
        } else if (this.selectedSortValue.value === 'desc') {
          filteredScreenContents = filteredScreenContents.sort(function(a, b) {
            return new Date(b.history.log[b.history.log.length-1].dateTime) - new Date(a.history.log[a.history.log.length-1].dateTime);
          });
        } else if (this.selectedSortValue.value === 'group' && this.isGroupSequence) {
          filteredScreenContents = filteredScreenContents.sort(function(a, b) {
            if(a.triggers.sequence == null || b.triggers.sequence == null) return 0;
            let aGroup = a.triggers.sequence.group.match(/\[(\d+)\]/)[1]
            let bGroup = b.triggers.sequence.group.match(/\[(\d+)\]/)[1]
            let aSequence = a.triggers.sequence.sequence
            let bSequence = b.triggers.sequence.sequence
            if (aGroup === bGroup) {
              return aSequence - bSequence
            } else {
              return aGroup - bGroup
            }
          })
        }

        // Filter filteredScreenContents based on selectedRegionFilters
        if (this.selectedRegionFilters && this.selectedRegionFilters.length > 0) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let regions = content.triggers.region.regions || [];
            return this.selectedRegionFilters.some(function(filter) { return regions.includes(filter) });
          }.bind(this));
        }
        // Filter filteredScreenContents based on selectedArtWorkFilters
        if (this.selectedArtWorkFilters && this.selectedArtWorkFilters.length > 0) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let styles = content.triggers.style.xml_styles || [];
            return this.selectedArtWorkFilters.some(function(filter) { return styles.includes(filter) });
          }.bind(this));
        }
        // Filter filteredScreenContents based on calendarValue[0] as start date and calendarValue[1] as end date
        if (this.calendarValue && this.calendarValue.length > 0 && this.isCalendar !== undefined) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let calendar = content.triggers.calendar
            let startDate = new Date(calendar.startDate)
            let endDate = new Date(calendar.endDate)
            let calendarValueStart = new Date(this.calendarValue[0])
            let calendarValueEnd = new Date(this.calendarValue[1])
            return (startDate >= calendarValueStart && startDate <= calendarValueEnd) || (endDate >= calendarValueStart && endDate <= calendarValueEnd)
          }.bind(this));
        }
        // Filter filteredScreenContents based on startTimeValue and endTimeValue 
        if (this.startTimeValue && this.endTimeValue && this.isTime !== undefined) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let startTime = content.triggers.time.startTime
            let endTime = content.triggers.time.endTime
            let startTimeValue = this.startTimeValue.split(':')
            let endTimeValue = this.endTimeValue.split(':')
            let startTimeHours = parseInt(startTimeValue[0])
            let startTimeMinutes = parseInt(startTimeValue[1])
            let endTimeHours = parseInt(endTimeValue[0])
            let endTimeMinutes = parseInt(endTimeValue[1])
            let startTimeContentHours = parseInt(startTime.split(':')[0])
            let startTimeContentMinutes = parseInt(startTime.split(':')[1])
            let endTimeContentHours = parseInt(endTime.split(':')[0])
            let endTimeContentMinutes = parseInt(endTime.split(':')[1])
            return ((startTimeHours < startTimeContentHours || (startTimeHours === startTimeContentHours && startTimeMinutes <= startTimeContentMinutes)) && (endTimeHours > endTimeContentHours || (endTimeHours === endTimeContentHours && endTimeMinutes >= endTimeContentMinutes)))
          }.bind(this));
        }
        
        return filteredScreenContents;
      },
      artworkFilters() {
        if(this.screenContents && this.screenContents.length > 0) {
          let filters = []

          this.screenContents.forEach(screenContent => {
            if(screenContent.triggers.style && screenContent.triggers.style.xml_styles && screenContent.triggers.style.xml_styles.length > 0) {
              filters.push(screenContent.triggers.style.xml_styles)
            }
          })

          return filters
        }
        
        return false
      },
      regionFiltersList(){
        if(this.screenContents && this.screenContents.length > 0) {
          let filters = []

          this.screenContents.forEach(screenContent => {
            if(screenContent.triggers.region && screenContent.triggers.region.regions && screenContent.triggers.region.regions.length > 0) {
              filters.push(screenContent.triggers.region.regions)
            }
          })

          return filters
        }
        
        return false
      },

      // Calendar start date range text raedable
      calendarDateRange() {
        return (date) => {
          return moment(date).format('DD MMM YYYY HH:mm a')
        }
      },
      isIndefinite() {
        return (startEndDateRange) => {
          return startEndDateRange === '|' || startEndDateRange === ''
        }
      },

      // Get the start and end date from the date range
      toDateRangeText() {
        return (startEndDateRange) => {
          if(this.isIndefinite(startEndDateRange)) {
            return 'Indefinite';
          }
          let dates = startEndDateRange.split('|')
          let startDate = moment(dates[0]).format('DD MMM YYYY HH:mm a')
          let endDate = moment(dates[1]).format('DD MMM YYYY HH:mm a')
          if(startDate === endDate) {
            return "Never"
          }
          return "from " + startDate + " to " + endDate
        }
      },
    },

    watch: {
      sliderGroups: {
          handler: function (newValue, oldValue) {
            this.debounceSaveLinkedMetadata();
          },
          deep: true,
      },

      // Sport - Rugby Trigger Watches
      'selectedContentForTriggerEdit.triggers.sportRugby.competitionId': function(newVal, oldVal) { if(newVal !== oldVal) {this.getRugbyTeams();}},
      'selectedContentForTriggerEdit.triggers.sportRugby.seasonId': function(newVal, oldVal) { if(newVal !== oldVal) {this.getRugbyTeams();}},
      triggerBuilderDialog : function()
      {
        
        if(this.triggerBuilderDialog)
        {
          if(this.screenLevelTriggers.includes('ladbrokes'))
          {
            this.customTriggerWatchers('ladbrokes', "mode", "", true)
            this.customTriggerWatchers('ladbrokes', "events", "", true)
            this.customTriggerWatchers('ladbrokes', "market", "", true)
            this.customTriggerWatchers('ladbrokes', "selection", "", true)
          }
          if(this.screenLevelTriggers.includes('globalLBCnews'))
          {
            this.previewUrl = null
            var mode = this.selectedContentForTriggerEdit.triggers.golive.islive;
            switch(mode)
            {
              case "Published":
                mode = "live";
                break;
              default:
                mode = mode.toLowerCase();
                break;
            }
            this.getPreviewPackage(mode, this.selectedContentForTriggerEdit.id)
          }
        }
      },
      // Watch for changes in showAdvancedFilters, if its false, then reset all filters and fix this error Vue warn]: Error in callback for watcher "showAdvancedFilters": "TypeError: Cannot read properties of undefined (reading '0')
      showAdvancedFilters: function (newVal, oldVal) {
        if (newVal === false) {
          this.selectedGoLiveValue = []
          this.selectedDayOfWeekValue = []
          this.selectedSortValue = this.isGroupSequence ? {text: 'Group', value: 'group'} : {text: 'Asc', value: 'asc'}
          this.selectedRegionFilters = []
          this.selectedArtWorkFilters = null
          this.limit = 10
          this.pageNum = 1
          this.$nextTick()
        }
      },
      selectedCampaignBurstScreen: function (newVal) {
        this.getAllLocations()
      },
      selectedContentForTriggerEdit: function (newVal, oldVal) {
        if(this.selectedContentForTriggerEdit !== null)
        {
          let parsedTriggers
          try {
            parsedTriggers = newVal.triggers
            this.selectedContentForTriggerEdit.triggers = parsedTriggers
          } catch(err) {  // Send error on catch with relevant pop up message
            console.log(err)
          }

          // match up with screen level triggers, and make strings = booleans to avoid bug with fields not populating properly
          for (var i = 0; i < this.screenLevelTriggers.length; i++) {
            for (
              var k = 0;
              k <
              Object.getOwnPropertyNames(
                parsedTriggers[this.screenLevelTriggers[i]],
              ).length;
              k++
            ) {
              if (
                parsedTriggers[this.screenLevelTriggers[i]][
                  Object.getOwnPropertyNames(
                    parsedTriggers[this.screenLevelTriggers[i]],
                  )[k]
                ] === 'false'
              ) {
                parsedTriggers[this.screenLevelTriggers[i]][
                  Object.getOwnPropertyNames(
                    parsedTriggers[this.screenLevelTriggers[i]],
                  )[k]
                ] = false
              }
              if (
                parsedTriggers[this.screenLevelTriggers[i]][
                  Object.getOwnPropertyNames(
                    parsedTriggers[this.screenLevelTriggers[i]],
                  )[k]
                ] === 'true'
              ) {
                parsedTriggers[this.screenLevelTriggers[i]][
                  Object.getOwnPropertyNames(
                    parsedTriggers[this.screenLevelTriggers[i]],
                  )[k]
                ] = true
              }
            }
          }
        }
      },
    },
    methods: {
        hasHyperlocalData(screenContent) {
          return screenContent.triggers.hyperLocal.identifierKey != "" || screenContent.triggers.hyperLocal.locationInclusions != "" || screenContent.triggers.hyperLocal.locationExclusions != ""
        },

        stopKey(event) {
          if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault()
            event.stopPropagation()
            event.stopImmediatePropagation()
            console.log("ss1","events stopped")
          }
          console.log(event)
        },

        submitSearch(triggerParam, itemParam , index,event) {
          console.log("ss1","submit search",event)
          if (event.key === 'Enter') {
            this.stopKey(event)
            
            // make a copy of regionsSearchText array
            let regionsSearchTextCopy = this.regionsSearchText[itemParam].slice()

            // prevent enter from selecting an item in the dropdown list
            console.log("ss1","running submit search",event)
            console.log("ss1",regionsSearchTextCopy)
            // search through the regions array and if the search term matches case insensitive and trimmed to that search term, use that one
            // if not, push the search term to the regions array
            if(this.searchInputs[index] === null || this.searchInputs[index] === undefined || this.searchInputs[index] === "")
              return;
            // is there an exact match?
            let ind = this.regions.findIndex((item) => item.toLowerCase().trim() === this.searchInputs[index].toLowerCase().trim())
            if(ind !== -1)
            {
              let val = this.regions[ind];
              if(!regionsSearchTextCopy.includes(val))
              {
                // get the value from the regions array and push it to the regionsSearchText array  
                console.log("ss1","value exists, adding")
                regionsSearchTextCopy.push(val)
              }
              else
                console.log("ss1","not added, already in list")
            }
            else 
            {
              console.log("ss1","value doesn't exist")
              // does the value contains the search term, filter the original list
              let filtered = this.regions.filter((item) => item.toLowerCase().includes(this.searchInputs[index].toLowerCase()))
              // if there's only 1 item (partial match)
              if(filtered.length === 1)
              {
                console.log("ss1","filtered: " + filtered)
                // add it if filtered[0] does not exist in the regionsSearchText array
                if(!regionsSearchTextCopy.includes(filtered[0]))
                regionsSearchTextCopy.push(filtered[0])
              }
              else if(filtered.length == 0)
              {
                // add the search term
                console.log("ss1","filtered: " + filtered)
                regionsSearchTextCopy.push(this.searchInputs[index])
              }
            }
            this.searchInputs[index] = ''
            console.log("ss1",regionsSearchTextCopy)
            console.log("ss1", "saving")
            this.updateTrigger(triggerParam, itemParam, regionsSearchTextCopy.join(','))
            this.$nextTick()
            // finally set the regionsSearchText array to the copy
            this.$set(this.regionsSearchText, itemParam, regionsSearchTextCopy)

            console.log("ss1", "saved")
          }
        },

      resetCalendarView() {
      let dateRange = this.toDateRange(this.selectedContentForTriggerEdit.triggers.dateRange.startEndDateRange)
      const pickerObj = this.$refs.picker[0]
      if(pickerObj)
      {
        // date - 1 month
        pickerObj.monthDate = moment(dateRange.startDate).subtract(1, 'month').toDate()
        // current date
        pickerObj.nextMonthDate = moment(dateRange.startDate).toDate()
      }
      
    },

    getDateRangeFromPickerRef() {
      const pickerObj = this.$refs.picker[0]
      if(pickerObj)
      {
        const dateRange = {startDate: pickerObj.start, endDate: pickerObj.end}
        return dateRange
      }
      return this.toDateRange('-')
    },

    setIndefinite(picker) {
      this.selectedContentForTriggerEdit.dirtyDateRange = true
      this.selectedContentForTriggerEdit.dateRangeIndefinite = !this.selectedContentForTriggerEdit.dateRangeIndefinite
      let isIndefinite = this.selectedContentForTriggerEdit.dateRangeIndefinite
        if(isIndefinite){ // Checkbox true
          this.selectedContentForTriggerEdit.preIndefinite = this.toTriggerDateRange(this.getDateRangeFromPickerRef()) // Save the previous date range
          this.updateTrigger('dateRange', 'startEndDateRange', '|')
        } else {
          let unsetDate = this.selectedContentForTriggerEdit.preIndefinite || this.selectedContentForTriggerEdit.previousDateRange// Set the date to the previous selection
          if (!unsetDate || this.isIndefinite(unsetDate))
          {
            unsetDate = this.toTriggerDateRange(this.toDateRange('-')) // Set the date to today and tomorrow
          }
          this.updateTrigger('dateRange', 'startEndDateRange', unsetDate)
          this.selectedContentForTriggerEdit.preIndefinite = unsetDate
          this.resetCalendarView()
        }
        this.calendarShowHide(isIndefinite)
        this.applyIndefinite()
    },

    calendarShowHide(hide) {
      //return;
      // all html items with "calendar" class
      let calendarItems = document.getElementsByClassName("calendars")
      if(calendarItems[0])
        calendarItems[0].style.display = hide ? 'none' : 'block'
      return;
      const pickerObj = this.$refs.picker[0]
        if(calendarItems)
        {
          // hide each child of pickerObj
          calendarItems.$children.forEach(child => {
            child.$el.style.display = hide ? 'none' : 'block'
          })
        }
    },

    applyIndefinite() {
      let isIndefinite = this.isIndefinite(this.selectedContentForTriggerEdit.triggers.dateRange.startEndDateRange)
      this.$set(this.selectedContentForTriggerEdit, 'dateRangeIndefinite', isIndefinite)
    },

    handleCalendarCancel(picker){
      this.updateTrigger('dateRange', 'startEndDateRange', this.selectedContentForTriggerEdit.previousDateRange)
      this.applyIndefinite()
      picker.clickCancel()
    },

    handleCalendarApply(picker){
      this.selectedContentForTriggerEdit.dirtyDateRange = false
      picker.clickApply()
    },

    handleCalendarSelect(dateRange) {
      this.selectedContentForTriggerEdit.dirtyDateRange = true
    },

    handleCalendarToggle(event) {
      if(event) // open
      {
        const dateRange = this.selectedContentForTriggerEdit.triggers.dateRange.startEndDateRange
        this.selectedContentForTriggerEdit.dirtyDateRange = false
        this.selectedContentForTriggerEdit.previousDateRange = dateRange
        this.selectedContentForTriggerEdit.preIndefinite = dateRange
        this.selectedContentForTriggerEdit.dateRangeIndefinite = this.isIndefinite(dateRange)
        this.$nextTick(() => {
          // Code to execute after the DOM has been updated
          this.calendarShowHide(this.selectedContentForTriggerEdit.dateRangeIndefinite)
        });
      }
      else if (this.selectedContentForTriggerEdit.dirtyDateRange) // close
      {
        // closed without applying
        this.updateTrigger('dateRange', 'startEndDateRange', this.selectedContentForTriggerEdit.previousDateRange) 
        this.applyIndefinite()
      }
    },

    toDateRange(dateRange) {    
      if(this.isIndefinite(dateRange)) {
        // Set the start date to 1900-01-01 00:00 and endDate to 2100-01-01 00:00
        return { startDate: moment('1900-01-01 00:00', this.dateRangeFormat), endDate: moment('2100-01-01 00:00', this.dateRangeFormat) }
      } else {
        var sd = moment().startOf('day');
        var ed = moment().startOf('day').add(1, 'day');
        try {
          var dateRangeParts = dateRange.split('|')
          if (dateRangeParts.length == 2) {
            sd = moment(dateRangeParts[0], this.dateRangeFormat)
            ed = moment(dateRangeParts[1], this.dateRangeFormat)
          }
        } catch (e) {

        }
        return { startDate: sd, endDate: ed }
      }
    },

    toTriggerDateRange(dateRange) {
      return moment(dateRange.startDate).format(this.dateRangeFormat) + "|" + moment(dateRange.endDate).format(this.dateRangeFormat)
    },

      debounceSaveLinkedMetadata: debounce(async function(sliderGroup) {
        this.sliderGroups.forEach(async sliderGroup => {
          if(sliderGroup.shouldSave)
          {
            await sliderGroup.sliders.forEach(async slider => {
              let findSC = this.screenContents.find(content => content.id === slider.campaignId);
              if(findSC != null && slider.shouldSave)
              {
                this.updateTrigger("sov","percentage", slider.value, findSC)
                await this.pushTriggerToQueue(findSC,"sov.percentage", slider.value)
              }
              slider.shouldSave = false;
            });
            sliderGroup.shouldSave = false;
            this.saveNextTrigger()
            this.selectedContentForTriggerEdit = null
          }
        });
      }, 1000), // Adjust the debounce time as needed

      // Function to check if any value from list1 exists in list2
      checkValuesExist(list1, list2) {
          for (let i = 0; i < list1.length; i++) {
              if (list2.includes(list1[i])) {
                  return true; // If any value from list1 exists in list2, return true
              }
          }
          return false; // If no matching values found, return false
      },

      allowCreator() {
        return this.$store.state.Permissions.creatorEditor && this.checkValuesExist(this.creatorEditorTriggers, this.screenLevelTriggers)
      },

      getPreviewPackage (publishState, campaignBurstScreenContentId) {
        this.generatingPreview = true
        this.lastPublishState = publishState
        CampaignController.getPreview({
          campaignBurstScreenId: this.selectedCampaignBurstScreen.id,
          frameId: this.previewLocation.idA,
          date: this.previewDate,
          time: this.previewTime,
          live: publishState,
          width: this.previewWidth,
          height: this.previewHeight,
          campaignBurstScreenContentId: campaignBurstScreenContentId
        })
        .then((res) => {
          this.renderIframe = true
          this.previewUrl = res.data.url
        })
        .then(() => {
          this.previewAtFullScale()
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })
        .finally(() => {
          this.generatingPreview = false
        });
      },

      customDescriptorsEmpty(sc) {
        if(!sc.triggers.customDescriptors) {
          return true
        }
        // are they all null or empty?
        return Object.values(sc.triggers.customDescriptors).every(v => v === null || v === '')
      },

      // Ladbrokes change handler
      ladbrokesChangeHandler(triggerName, triggerContent, affectOnly) {
        if(triggerName =='ladbrokes')
        {
          var mode = this.selectedContentForTriggerEdit.triggers.ladbrokes.mode;
          var assignTo = "";
          mode = mode.toLowerCase();
          if(mode)
          { 
            // get the relevant ID
            var id = "";
            var fetchWhich = "";
            if(triggerContent == 'market')
            {
              fetchWhich = 'market';
              assignTo = 'selection';
              mode = 'selection';
              id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.market;
            }
            else if(triggerContent == 'events')
            {
              fetchWhich = 'event';
              assignTo = 'market';
              mode = "market";
              id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.events;
            }
            else if (triggerContent == mode || triggerContent == 'mode')
            {
              if(mode == 'class')
              {
                fetchWhich = 'events';
                id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.class;
              }
              else if(mode == 'category')
              {
                fetchWhich = 'events';
                id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.category;
              }
              else if(mode == 'type')
              {
                fetchWhich = 'events';
                id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.type;
              }
              assignTo = 'events';
            }
            else 
              return;

            // clear existing values
            if(fetchWhich == "events")
            {
              this.$set(this.ladbrokesInfo, "events", []);
              if(!affectOnly)
              {
                this.$set(this.ladbrokesInfo, "market", []);
                this.$set(this.ladbrokesInfo, "selection", []);
                this.selectedContentForTriggerEdit.triggers.ladbrokes.events = ""
                this.selectedContentForTriggerEdit.triggers.ladbrokes.market = ""
                this.selectedContentForTriggerEdit.triggers.ladbrokes.selection = ""
              }
            }
            else if (fetchWhich == 'event')
            {
              this.$set(this.ladbrokesInfo, "market", []);
              if(!affectOnly)
              {
                this.$set(this.ladbrokesInfo, 'selection', []);
                this.selectedContentForTriggerEdit.triggers.ladbrokes.market = ""
                this.selectedContentForTriggerEdit.triggers.ladbrokes.selection = ""
              }
            }
            else if (triggerContent == 'mode')
            {
              this.$set(this.ladbrokesInfo, "events", []);
              this.$set(this.ladbrokesInfo, "market", []);
              this.$set(this.ladbrokesInfo, "selection", []);
              this.selectedContentForTriggerEdit.triggers.ladbrokes.events = ""
              this.selectedContentForTriggerEdit.triggers.ladbrokes.market = ""
              this.selectedContentForTriggerEdit.triggers.ladbrokes.selection = ""
            }

            const numberRegex = /\((\d+)\)/;
            const match = id.match(numberRegex);
            if (match) {
              const number = match[1];
              id = Number(number); // Output: 40
              
            } else {
              id = 0
            }

            if(id > 0 && mode)
              this.getLadbrokesInfo(fetchWhich,assignTo,mode,id);
          }
        }
      },

      // safe list split
      safeListSplit(list, separator = ",") {
        if (list) {
          return list.split(separator).map(item => item.trim()) // map to remove whitespace
          
        }
        return []
      },

      async copyText(txt) {
        try {
          await navigator.clipboard.writeText(txt);
          this.$root.$emit('snackbarSuccess','Copied to clipboard')
        } catch (error) {
          console.error('Failed to copy text: ', error);
        }
      },
      // Update artwork object 
      async updateArtwork(newData) {
        let oldVal = this.screenContents.find(item => item.id === newData.data.id)
        //const artAtIndex = this.screenContents.indexOf(oldVal)
        //this.screenContents.splice(artAtIndex, 1, newData.data)
        oldVal = newData.data
        await this.$nextTick()
      },
      // Show/Hide timeline
      toggleTimeline(id) {
        if(this.cardOne[id] == 'start'){
          this.$set(this.cardOne, id, 'flipped')
        } else {
          this.$set(this.cardOne, id, 'start')
        }
      },
      // Artwork(Creatives) drag and drop functions
      dragFileUpload(id, bgColor) {
        if (document.getElementById(id) != null) {
          document.getElementById(id).style.backgroundColor = bgColor
        }
      },
      dragFilePlusUpload(id, show) {
        const fileUpload = document.getElementById(id)
        if (fileUpload != null) {
          fileUpload.style.opacity = (show === 'hide') ? 0 : 1
        }
      },
      clickFileUpload() {
        const fileUpload = document.getElementById('mediaUpload')
        if (fileUpload != null) {
          fileUpload.click()
        }
      },
      // Copy/paste triggers global storage
      addSelectedList(obj){
        this.$store.dispatch('Clipboard/addSelected', obj)
      },
      checkSelectedList(id){
        let exists = this.$store.state.Clipboard.selected.filter(item => item.id === id)
        return exists != null && exists.length > 0
      },

      //Sequence Color
      isSelectedColor(paramName, itemParamName, currentColor) {
      // Add logic to check if the current color is selected
      return (
        this.selectedContentForTriggerEdit.triggers[paramName][itemParamName] === currentColor
      );
    },
      // Copy/paste triggers function
      selectedTriggersCopy(data){
        data.triggers = Object.fromEntries(Object.entries(data.triggers).filter(([key]) => this.screenLevelTriggers.includes(key)));
        this.$store.dispatch('Clipboard/setClipboard', data)
      },

      async getCampaignById() {
        this.lastUpdatedTime = moment().format('YYYY-MMM-DD HH:MM:SS')
          await CampaignController.getCampaign(this.$route.query.cid).then(async(res) => {
            this.campaign = res.data
            // Get all the bursts for the campaign
            this.campaignBursts = res.data.campaignBursts
            // Populate selected burst
            this.populateCampaignBurst(parseInt(this.$route.query.bid))
            // Populate selected screen
            this.populateCampaignBurstScreen(parseInt(this.$route.query.sid))
            this.updateBreadcrumbs()
            this.refreshScreens()
        })
      },

      publishStateConvert(publishState) {
        // required for legacy true(live)/false(preview) conversion
        const pState = publishState.toString().toLowerCase().trim()
        if(pState == "true")
          return "Published";
        else if (pState == "false")
          return "Preview";
        return publishState;
      },

      setPreviewLocation({ newLocation }) {
        if(newLocation) {
          if(newLocation.name == '') // Greg: not quite sure at this point, why the VUE breaks when this .name is empty (when clicking on a location row it would jump to the preview tab)
            newLocation.name = '-'
          this.previewLocation = newLocation
        } else {
          this.previewLocation = {}
        }
      },


      // Function that verifies the tabs for correctness
      checkForTabSelection() {
        var tabQuery = this.$route.query.tab
        if(tabQuery) {
          if(tabQuery == "uploadMedia" && this.allowCreator()) 
              tabQuery = 'creator';
          this.tabOptions.forEach((item, index) => {
            if(item === tabQuery) {
              this.tab = index
            }
          })
        }
        this.checkedTabOnLoad = true
      },
      // Function that updates the router based on tab selection  by user
      async updateTabSelection() {
        if(this.checkedTabOnLoad){
          for (let index = 0; index < this.tabOptions.length; index++) {
            const item = this.tabOptions[index]
            if(this.tab === index) {
              // Avoid redundant navigation replaces
              const curr = JSON.stringify({...this.$route.query})
              const replace = JSON.stringify({ ...this.$route.query, tab: item.toString()})
              if(curr !== replace){
                this.$router.replace({ query: { ...this.$route.query, tab: item.toString() } })
              }
            }
          }
        }
      },

      regionTitle(regions) {
        const arr = regions.split(',')
        if (arr.length > 1)
          return 'regions:'
        else
          return 'region:'
      },

      isActiveTransitIcon(string, search) {
        string = string.toLowerCase()
        string = string.split(",")
        let found = false

        string.forEach(element => {
          if(element === search) 
            found = true
        });

        return found
      },
      // Duisplay locaton data 
      prettifyLocationMetadata(location) {
        var output = ""
        if(location != undefined)
        {
          output += "<v-row cols=6>"
          output += ((location.idA?location.idA:location.idE)) ? "<b>Frame ID:</b> " + (location.idA?location.idA:location.idE) + ", " : ""
          output += (location.name) ? "<b>Location:</b> " + location.name + ", " : ""
          output += (location.latitude) ? "<b>Lat/Long:</b> " + location.latitude + "," + location.longitude + ", " : ""
          output += (location.region) ? "<b>Region:</b> " + location.region + ", " : ""
          output += (location.staticOnly) ? "<b>Static Only:</b> " + location.staticOnly + ", " : ""
          output += (location.hfssRestriction) ? "<b>HFSS Restriction:</b> " + location.hfssRestriction + ", " : ""
          output += (location.alcoholRestriction) ? "<b>Alcohol Restriction:</b> " + location.alcoholRestriction + ", " : ""
          output += (location.modestyRestriction) ? "<b>Cultural Restriction:</b> " + location.modestyRestriction + ", " : ""
          output += (location.regulatoryRestriction) ? "<b>Regulatory Restriction:</b> " + location.regulatoryRestriction + ", " : ""
          output += (location.weather) ? "<b>Weather:</b> " + location.weather + ", " : ""
          output += (location.customCallouts) ? "<b>Custom Callouts:</b> " + location.customCallouts + ", " : ""
          if(output.length > 2)
            output = output.slice(0,-2).trim()
          output += "</v-row>"
        }
        return output
      },

      triggerErrorMessage(errors, param) {
        if(errors && errors.length > 0) {
          return errors
        }
        else {
          return ""
        }
      },

      checkTriggerRules(event, item) {
        // Don't allow typing of numbers
        if(item.type === 'number') {
          // keypress
          if ((event.which < 48 && event.which !== 45) || event.which > 57)
              event.preventDefault()
          // Paste
          if(event.type === 'paste') {
            const input = event.clipboardData.getData('text')
            const number = new RegExp(/\d/)
            if(!number.test(input))
              event.preventDefault()
          }
        }
      },
      disableSaveTriggerBtn(errors, param) {
        // Disable the save button
        this.disableTriggerSaveBtn = (errors && errors.length > 0 || this.$refs[param][0].badInput) ? true : false
      },
      // 
      async getAllLocations () {
        if(this.selectedCampaignBurstScreen)
          await CampaignController.getLocationList(this.selectedCampaignBurstScreen.id)
            .then((res) => {
              this.locationsList = res.data.locationList
              this.locationsList = this.expandLocationExtraInfo(this.locationsList)
              this.locationsList = this.locationsList.sort(function(a,b) { return a.name.localeCompare(b.name)}) // sort
            })
      },
      expandLocationExtraInfo(locationtableData) {
        // expand extra location info to columns
        for (const ld in locationtableData)
        {
          const json = (locationtableData[ld].extraInfo) ? JSON.parse(locationtableData[ld].extraInfo) : {}
          
          for (const prop in json) {
            locationtableData[ld][prop] = json[prop]
          }
          delete locationtableData[ld].extraInfo
        }
        return locationtableData;
      },
      setAllLocations (allLocations) {
        if(allLocations)
          this.locationsList = allLocations
      },
      uploadFile(e) {
        this.uploadedFileCreative = Object.values(e.target.files)
        // Check if files or file is too large
        if(this.uploadedFileCreative.length > 0) {
          // Loop through files
          this.uploadedFileCreative.forEach(file => {
            // Check if file is too large
            if(file.size > this.selectedCampaignBurstScreen.screen.maxFileSizeKB * 1024) {
              // Set error flag
              this.isArtworkFileBigger = true
              // Append property error with value true to object file
              file.err = true
              // Show error message
              this.$root.$emit('snackbarWarning','Artwork file size is bigger then screen max file size.')
            } else{
              file.err = false
            }
          })
        }
        this.showUploadMediaDialog = true
      },
      dragFile(e) {
        // Accept the file
        this.uploadedFileCreative = Object.values(e.dataTransfer.files)
        // Change background colour
        this.$refs.dragDiv.style.backgroundColor = 'transparent'
        // Check if files or file is too large
        if(this.uploadedFileCreative.length > 0) {
          // Loop through files
          this.uploadedFileCreative.forEach(file => {
            // Check if file is too large
            if(file.size > this.selectedCampaignBurstScreen.screen.maxFileSizeKB * 1024) {
              // Set error flag
              this.isArtworkFileBigger = true
              file.err = true
              // Show error message
              this.$root.$emit('snackbarError','Artwork file size is bigger then screen max file size.')
            }
          })
        } else{
          file.err = false
        }
        // Shorthand for if a screen is selected
        this.showUploadMediaDialog = true
      },
      cancelUploadMedia() {
        // Empty array
        this.uploadedFileCreative = []
        // Reset file size check
        this.isArtworkFileBigger = false
        // Close upload modal
        this.showUploadMediaDialog = false
      },
      cancelCreatePlaceholder() {
        // Empty placeholder text
        this.draftPlacholderText = ""
        // Close draft placeholder modal
        this.showDraftPlaceholderDialog = false
      },
      removeUploadMedia(selectedItem) {
        // Filter out removed file
        this.uploadedFileCreative = [...this.uploadedFileCreative].filter(item => {
          if (item.name !== selectedItem.name)
            return item
        })
        // If list empty -> close upload modal
        if(this.uploadedFileCreative.length === 0)
          this.cancelUploadMedia()
      },
      imageDimensionCheck(media) {
        // defaults
        let result = false
        let icon = false
        let color = ''
        let message = ''
        // if w or h is incorrect
        if(media.width !== this.selectedScreen.width || media.height !== this.selectedScreen.height) {
          result = true
          icon = "mdi-close"
          color = 'red'
          message = 'Incorrect media dimensions'
          // If aspect ratio is correct
          if((this.selectedScreen.height / this.selectedScreen.width) === (media.height / media.width) && this.selectedScreen.dynamicScreen.isResponsive) {
            // up scale
            if(this.selectedScreen.height < media.height && this.selectedScreen.width < media.width) {
              result = true
              icon = "mdi-check"
              color = 'orange'
              message = 'Correct aspect ratio and will up scale media losing quality'
            }
            // down scale
            else {
              result = true
              icon = "mdi-close"
              color = 'orange'
              message = 'Correct aspect ratio and will up scale media losing quality'
            }
          }
        }
        // Else correct
        else {
          result = true
          icon = "mdi-check"
          color = 'green'
          message = 'Correct media dimensions'
        }
        // return the object
        return { result, icon, color, message }
      },
      limitText(str, numChars, fallbackStr) {
        if(str === null || str.length == 0)
          str = fallbackStr
        else if(str && str.length > numChars) 
          str = str.substring(0, numChars) + '...'
        return str
      },
      getGoLivePublishState(publishState) {
        const pState = publishState.toString().toLowerCase().trim()
        switch (pState)
            {
              case "archived":
                return "Archived";
              case "draft":
                return "Draft"
              case "preview":
              case "false": // legacy preview
                return "Preview"
              case "true": // legacy live
              case "live":
              case "published":
                return "Published"
            }
        return "Unknown"
      },
      updateSelectedDate(date) {
        this.selectedDate = date
      },
      sortScreenContents() {
        if(this.screenContents && this.screenContents.length > 0) {
          // Contains sequence trigger
          if(this.screenContents[0].triggers.sequence) {           
            // Sort based on group + sequence
            this.screenContents = this.screenContents.sort((a, b) =>
              this.sanitizeGroupFab(a.triggers.sequence.group,'index') - this.sanitizeGroupFab(b.triggers.sequence.group,'index') ||
              a.triggers.sequence.sequence - b.triggers.sequence.sequence
            )
          }
        }
      },
      populateCampaignBurst(bid) {
        const searchBurst = (what) => this.campaign.campaignBursts.find((element) => element.id === what)  
        this.selectedBurst = searchBurst(bid)
        if(this.selectedBurst == null)
          this.selectedBurst = this.campaignBursts[0]
        // Get all the screens for the selected burst
        const indexBurst = this.campaignBursts.findIndex(el => el.id === parseInt(this.selectedBurst.id))
        this.campaignBurstScreens = []
        // Get burst delivery type -> show/hide tabs based on it
        this.burstDeliveryType = this.campaignBursts[indexBurst].deliveryType.shortName
        // Append screen 
        this.campaignBursts[indexBurst].campaignBurstScreens.forEach(item => {
          this.campaignBurstScreens.push(item)
        })
      },
      
      populateCampaignBurstScreen(sid) {
        // find the screen by id and assign to respective variable
        this.selectedCampaignBurstScreen = this.campaignBurstScreens.find(el => el.screen.id === sid)
        if(this.selectedCampaignBurstScreen == null) 
          this.selectedCampaignBurstScreen = this.sortedCampaignBurstScreens[0]
        this.selectedScreen = this.selectedCampaignBurstScreen.screen
        const searchScreenLevelTriggers = (what) => this.campaignBurstScreens.find(element => element.id === what)
        // Get triggers added to screen level.
        this.screenLevelTriggers = searchScreenLevelTriggers(parseInt(this.selectedCampaignBurstScreen.id)).triggerList
        // Check if cinema API trigger exists
        if(this.screenLevelTriggers.includes('cinemaApi'))
          this.fetchInternationalShowtimeMovies()
        // Check if national Rail trigger exists
        if(this.screenLevelTriggers.includes('nationalRail'))
        {
          this.fetchNationalRailStations()
          this.fetchNationalRailOperators()
        }
        // Check if Heathrow Flight Arrivals trigger exists
        if(this.screenLevelTriggers.includes('heathrowFlightArrivals'))
        {
          this.fetchIataAirports()
        }

        if(this.screenLevelTriggers.includes('flightAPI'))
        {
          this.fetchAirports()
        }

        if(this.screenLevelTriggers.includes('flightAPI'))
        {
          this.fetchAirlines()
        }

        if(this.screenLevelTriggers.includes('ladbrokes'))
        {
          this.getLadbrokesInfo('class', 'class');
          this.getLadbrokesInfo('category', 'category');
          this.getLadbrokesInfo('type', 'type');
        }

        // Check if UEFA trigger exists
        if (this.screenLevelTriggers.includes('uefaTriggers') || this.screenLevelTriggers.includes('uefaTikTok')) {
          this.showFilterBar = true
          this.selectedTriggerFilter = ['tweet', 'instagram']
        }
        // Add tik tok filters if uefa TikTok is in the list
        if (this.screenLevelTriggers.includes('uefaTikTok')) {
          this.availableTriggerFilters.push({
            text: 'Tik Tok',
            val: 'tiktok',
          })
        }
      },

      // Fetch Rugby Teams
      getRugbyTeams() {
        // get rugby teams based on competitionId & seasonId
        try {
            const competitionId = parseInt(this.selectedContentForTriggerEdit.triggers.sportRugby.competitionId);
            const seasonId = parseInt(this.selectedContentForTriggerEdit.triggers.sportRugby.seasonId);
            if (competitionId && seasonId)
              this.fetchRugbyTeams(competitionId, seasonId);
          }
          catch(err) {
            // do nothing
          }
      },

      // Fetch Ladbrokes Info
      getLadbrokesInfo(mode, assignTo, expandMode, id) {
        // get ladbrokes info
        try {
            this.fetchLadbrokesInfo(mode, assignTo, expandMode, id);
          }
          catch(err) {
            // do nothing
          }
      },

      // Function to change burst data (review)
      async changeBurst() {
        this.populateCampaignBurst(this.selectedBurst.id)
        this.populateCampaignBurstScreen(this.selectedCampaignBurstScreen.screen.id)
        await this.$router.replace({ 
          query: {
            ...this.$route.query, 
            cbsid: this.selectedCampaignBurstScreen.id,
            sid: this.selectedCampaignBurstScreen.screen.id,
            bid:this.selectedBurst.id,
            bName: this.selectedBurst.name
          }
        }).then(async () => {
          this.updateBreadcrumbs()
          await this.refreshScreens()
        })
      },
      // Functions to chage screen data (review)
      changeScreen() {
        this.loadingScreens = true
        this.populateCampaignBurstScreen(this.selectedCampaignBurstScreen.screen.id)
        this.$router.replace({ 
          query: {
            ...this.$route.query,
            sid:this.selectedCampaignBurstScreen.screen.id,
            cbsid: this.selectedCampaignBurstScreen.id
          }
        }).then(async () => {
          this.selectedScreen = this.selectedCampaignBurstScreen.screen
          this.updateBreadcrumbs()
          await this.refreshScreens()
          this.loadingScreens = false
        })
      },
      // Function to update breadcrubms
      updateBreadcrumbs() {
        this.breadcrumbItems[1].text = this.campaign.name
        this.breadcrumbItems[1].href = '/#/dashboard/campaigns/campaign?cid=' + this.$route.query.cid
        if(this.breadcrumbItems[2])
        {
          this.breadcrumbItems[2].text = this.selectedBurst.name
          this.breadcrumbItems[2].href = '/#/dashboard/campaigns/campaign/creatives?cbsid=' + this.selectedCampaignBurstScreen.id + '&bid=' + this.selectedBurst.id + '&cid=' + this.$route.query.cid
        }
        if(this.breadcrumbItems[3])
          this.breadcrumbItems[3].text = this.selectedScreen.name
      },

      getColourFromIndex(item) {
        let i = this.colours.findIndex((element) => element[0] === item)
        return this.colours[i][1]
      },

      getTextColourFromIndex(item) {
        let i = this.colours.findIndex((element) => element[0] === item)
        return this.colours[i][2]
      },

      sanitizeGroupFab(item, get) {
        let split = item.split(' ')
        if(get === 'index') {
          let figure = (split[0]) ? split[0].replace(/[\[\]]+/g, '') : '0';
          return Number(figure)
        }
        else if(get === 'color') {
          return this.getColourFromIndex(item)
        }
        else if(get === 'text-color') {
          return this.getTextColourFromIndex(item)
        }
      },

      openTriggerEdit(lookup) {
        if(this.isMediaPlanner) {
          let mod = 0
          this.triggerForm.forEach((item, index) => {
            if(item.paramName === lookup)
              this.selectedTab = index - mod
            else if(item.paramName === 'socialMedia')
              mod += (this.selectedContentForTriggerEdit.triggers.tweet) ? mod : 1
          })
        } else {
          //snackbarerror
          this.$root.$emit('snackbarError', 'You do not have permission to edit triggers')
        }
        // assign regions and antiRegions to regionsSearchText
        if(this.selectedContentForTriggerEdit.triggers.region)
        {
          console.log("regions and antiRegions found")
          this.regionsSearchText['regions'] = this.selectedContentForTriggerEdit.triggers.region.regions ? this.selectedContentForTriggerEdit.triggers.region.regions.split(',') : []
          this.regionsSearchText['antiRegions'] = this.selectedContentForTriggerEdit.triggers.region.antiRegions ? this.selectedContentForTriggerEdit.triggers.region.antiRegions.split(',') : []
        }
      },
      // Function to open image in a new tab
      openImage(url) {
        // screenContent.media.url
        window.open(
          url,
          '_blank', // <- This is what makes it open in a new window.
        )
      },
      // Function to downloadImage
      downloadImage() {
        function forceDownload (blob, filename) {
          var a = document.createElement('a')
          a.download = filename
          a.href = blob
          // For Firefox https://stackoverflow.com/a/32226068
          document.body.appendChild(a)
          a.click()
          a.remove()
        }

        // Current blob size limit is around 500MB for browsers
        function downloadResource (url, filename) {
          if (!filename) filename = url.split('\\').pop().split('/').pop()
          fetch(url, {
            headers: new Headers({
              Origin: location.origin,
            }),
            mode: 'cors',
          })
            .then((response) => response.blob())
            .then((blob) => {
              const blobUrl = window.URL.createObjectURL(blob)
              forceDownload(blobUrl, filename)
            })
            .catch((e) => {})
        }
        downloadResource(this.fullsizePreviewImgUrl)
      },

      async refreshScreens() {
        this.lastUpdatedTime = moment().format('YYYY-MMM-DD HH:mm:ss')
        await CampaignController.getCampaignBurstScreenContents(parseInt(this.$route.query.cbsid))
          .then((res) => {
            // Set each card to start 
            res.data.forEach((artwork) => {
              this.$set(this.cardOne, artwork.id, 'start')
            })
            this.selectedContentForTriggerEdit = null
            // update array of artworks after screen refresh
            this.screenContents = res.data
            // magic function to update the data after it has been changed
            this.$nextTick()
            // sort out functions below later
            this.populateTriggerForm()
            this.populateScreenContentTriggers()
            this.sortScreenContents()
            this.getLinkedGroupIds();

            // assign screenContents to sov sliders
            this.populateSovSliders()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError',''+err.response.data.message)
          })
      },
      async getLinkedGroupIds() {
        this.screenContents.forEach(async (s)=>{
          try {
            var res = await CampaignController.getLinkedFormats(s.id);
            if(res?.data && res?.data.length > 0 && res?.data[0]?.contentLinkGroupId) {
              this.lookuptableLinkedGroupIdBySId[s.id] = res.data[0].contentLinkGroupId;
              this.lookuptableLinkedGroupIdBySId = {
                ...this.lookuptableLinkedGroupIdBySId,
                [s.id]: res.data[0].contentLinkGroupId,
              };
            }
          } catch(err) {
            console.log("getLinkedGroupIds > "+err);
          }
        })
      },
      populateSovSliders() {
        if(this.screenLevelTriggers.includes('sov'))
        {
            let sliders = [];
            this.sliderGroups = [];
            // do all campaigns in the linkedGroup have an item in metaData.sliders?
            this.screenContents.forEach(item => {
              let found = false;
              if(!found) {
                sliders.push({ isDirty: false,campaignId: item.id, value: Number(item.triggers["sov"]["percentage"])});
              }
              item.groupId = this.selectedCampaignBurstScreen.id;
            });
            this.sliderGroups.push({isDirty: false, groupId: this.selectedCampaignBurstScreen.id, sliders: sliders});
        }
      },

      // Upload btn function
      confirmUploadToCreatives() {
        const formData = (this.socialMediaUrl !== null || this.uploadedFileCreative !== null) ? new FormData() : null
        
        if (this.socialMediaUrl !== null) {
          this.mediaUploadingFlag = true
          // If extracting image from tweet
          formData.append('url', this.socialMediaUrl)
          formData.append('campaignId', parseInt(this.$route.query.cid))

          MediaTypeController.uploadMediaAsUrl(formData)
            .then((resUpload) => {
              // Standart Upload
              let counter = 0
              for (var x = 0; x < resUpload.data.length; x++) {
                CampaignController.addContent({
                  mediaId: resUpload.data[x].media.id,
                  triggers: resUpload.data[x].trigger,
                  campaignBurstScreenId: this.selectedCampaignBurstScreen.id,
                })
                  .then(() => {
                    counter++
                    if (counter === resUpload.data.length) {
                      this.$root.$emit('snackbarSuccess','Successfully uploaded creative to selected screen(s).')
                      this.mediaUploadingFlag = false
                      this.uploadedFileCreative = null
                      this.showUploadCreativeDialog = false
                      this.socialMediaUrl = null
                      this.refreshScreens()
                    }
                  })
                  .catch((err) => {
                    this.mediaUploadingFlag = false
                    this.$root.$emit('snackbarError', ''+err.response.data.message)
                  })
              }
            })
            .catch((err) => {
              this.mediaUploadingFlag = false
              this.$root.$emit('snackbarError', ''+err.response.data.message)
            })
        }
        if (this.uploadedFileCreative !== null) {
          this.uploadedFileCreative.forEach(item => {
            // Uploading has started flag (use for a spinner etc)
            this.mediaUploadingFlag = true
            // If uploading from file input instead.
            const formData = new FormData()
            formData.append('file', item)
            formData.append('campaignId', parseInt(this.$route.query.cid))
            CampaignController.uploadMedia(formData).then((res) => {
              if(!this.artworkReplace){
                CampaignController.addContent({
                  mediaId: res.data.id,
                  triggers: '{}',
                  campaignBurstScreenId: this.selectedCampaignBurstScreen.id,
                })
                  .then(() => {
                    this.$root.$emit(
                      'snackbarSuccess',
                      'Successfully uploaded creative to selected screen(s).',
                    )
                    this.mediaUploadingFlag = false
                    this.uploadedFileCreative = []
                    this.showUploadMediaDialog = false
                    this.socialMediaUrl = null
                    this.refreshScreens()
                  })
                  .catch((err) => {
                    this.$root.$emit('snackbarError', ''+err.response.data.message)
                    this.mediaUploadingFlag = false
                  })
              } else { // Replace Upload
                CampaignController.replaceContentArtwork({
                  campaignBurstScreenContentId: this.selectedArtToRepl, 
                  mediaId: res.data.id
                }).then(() => {
                  this.$root.$emit('snackbarSuccess','Successfully replaced creative to selected screen(s).',)
                      this.mediaUploadingFlag = false
                      this.uploadedFileCreative = null
                      this.showUploadMediaDialog = false
                      this.socialMediaUrl = null
                      this.selectedArtToRepl = null
                      this.artworkReplace = false
                      this.refreshScreens()
                })
                .catch((err) => {
                    this.mediaUploadingFlag = false
                    this.artworkReplace = false
                    this.$root.$emit('snackbarError', ''+err.response.data.message)
                  })
              }
            })
          })
        }
      },
      // Placeholder confirm function
      confirmCreatePlaceholder() {
        this.placeholderCreatingFlag = true
        CampaignController.addDraftPlaceholder(this.selectedCampaignBurstScreen.id, this.draftPlacholderText)
          .then(() => {
            this.$root.$emit(
              'snackbarSuccess',
              'Successfully created placeholder "' + this.draftPlacholderText + '',
            )
            this.placeholderCreatingFlag = false
            this.draftPlacholderText = ""
            this.showDraftPlaceholderDialog = false
            this.socialMediaUrl = null
            this.refreshScreens()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
            this.placeholderCreatingFlag = false
          })
      },
      async deleteLinkedContent() {
        this.selectedContentLinkedFormats.forEach((x)=>{
          if(!x.campaignBurstScreenContent.isDeleted && 
          x.campaignBurstScreenContent.id != this.linkedContentForCopy.id) {
            CampaignController.deleteBurstContent({
              campaignBurstScreenId:  x.campaignBurstScreenContent.campaignBurstScreenId,
              campaignBurstScreenContentId: x.campaignBurstScreenContent.id,
            })
              .then(() => {
//                this.refreshScreens()
//                this.deleteContentDialog = false
                this.$root.$emit(
                  'snackbarSuccess',
                  'Successfully deleted creative from burst screen.',
                )
              })
              .catch((err) => {
                this.$root.$emit('snackbarError', ''+err.response.data.message)
              })
          }
        });
        this.deleteLinkedContentDialog = false;
      },
      async deleteContentFromScreen() {
        var res = await CampaignController.getLinkedFormats(this.selectedContentForTriggerEdit.id);
        if(res?.data?.length > 0 && res.data[0]?.contentLinkGroup?.contentLinkGroupType?.shortName == "passivelink") {
          this.selectedContentLinkedFormats = res.data;
          this.linkedContentForCopy = JSON.parse(JSON.stringify(this.selectedContentForTriggerEdit));
          this.deleteLinkedContentDialog = true;
        }
        CampaignController.deleteBurstContent({
          campaignBurstScreenId:  this.selectedContentForTriggerEdit.campaignBurstScreenId,
          campaignBurstScreenContentId: this.selectedContentForTriggerEdit.id,
        })
          .then(() => {
            this.refreshScreens()
            this.deleteContentDialog = false
            this.$root.$emit(
              'snackbarSuccess',
              'Successfully deleted creative from burst screen.',
            )
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },
      editClipboardTriggers() {
        this.editClipboardSelectedTriggers = {};
        for (var key in this.$store.state.Clipboard.triggersCopyData.triggers) {
          this.editClipboardSelectedTriggers[key] = false;
        }
        this.showEditClipboardDialog = true;
      },
      async saveEditClipboardTriggers() {
        // Assign list of selected artworks to paste into to a respective var 
        const selectedList = this.$store.state.Clipboard.selected
        const triggerSource = JSON.parse(JSON.stringify(this.$store.state.Clipboard.triggersCopyData));

        var newTriggers = {}

        for(let key in triggerSource.triggers) {
          if(this.editClipboardSelectedTriggers[key] && triggerSource.triggers[key]) {
//            delete this.$store.state.Clipboard.triggersCopyData.triggers[key];
            newTriggers[key] = triggerSource.triggers[key];
          }
        }
        triggerSource.triggers = newTriggers;

        let sourceScfte = triggerSource;//this.screenContents.find(x => x.id === triggerSource.id)
        // Loader start
        this.$store.dispatch('Clipboard/toggleLoader', true)
        // For loop traversing the selected cards and assigning the triggers to respective artwork for update
        for(var i=0;i<selectedList.length;i++){
          let scfte = selectedList[i]
          // copy triggers from source to target
          for(let key in newTriggers) {
            if(Object.hasOwn(scfte.triggers,key)) {
              scfte.triggers[key] = JSON.parse(JSON.stringify(sourceScfte.triggers[key]));
            }
          }
//          scfte.triggers = JSON.parse(JSON.stringify(sourceScfte.triggers))
          this.saveTriggerQueue(scfte)
        }
        // Call recursive function to update db with updated triggers
        this.saveNextTrigger()

        this.showEditClipboardDialog = false;
      },
      selectAllScreensClipboard() {
        for(let i = 0; i < this.screenContents.length;i++) {
          if(!this.checkSelectedList(this.screenContents[i].id)) {
            this.$store.dispatch('Clipboard/addSelected', this.screenContents[i]);
          }
        }
      },

      updateCoord(triggerName, triggerContent, val) {
        this.selectedContentForTriggerEdit.triggers[triggerName][triggerContent] =
          val.x * this.ratio + ':' + val.y * this.ratio
      },

      getRule(val, rules) {
        const rulePipes = rules.split("|")
        for(let i = 0; i < rulePipes.length; i++)
        {
          if(rulePipes[i].startsWith(val))
          {
            const pipeParts = rulePipes[i].split(":")
            if(pipeParts.length > 1)
              return parseInt(pipeParts[1])
          }
        }
        return 0
      },

      customTriggerWatchers(triggerName, triggerContent, content, affectOnly = false) {

        // Ladbrokes - Custom Watches
        if(triggerName == "ladbrokes") {
          this.ladbrokesChangeHandler(triggerName, triggerContent, affectOnly)
        }
        if(triggerName == "globalLBCnews") {
          var iframe = document.getElementById('htmlPreviewIframe')
          if(iframe)
          {
            var functionToCall = null
            var valToPass = content
            switch(triggerContent) {
              case "moveX":
                functionToCall = "setBGImageX"
                break
              case "moveY":
                functionToCall = "setBGImageY"
                break
              case "zoom":
                functionToCall = "setBGZoom"
                break
              case "storyState":
                functionToCall = "setOverlay"
                valToPass = "'"+content.toLowerCase()+"'"
                break
              case "headline":
                functionToCall = "setHeadline"
                break
              case "subHeadline":
                functionToCall = "setSubHeadline"
                break
            }
            if(functionToCall) {
              iframe.contentWindow.postMessage({ event: JSON.stringify({iframe_id:"12345",function:functionToCall,parameter: valToPass})}, '*') 
            }
            
          }
        }
      },

      updateTriggerStrArr(triggerName, triggerContent, content, pos) {
        const editedContent = this.selectedContentForTriggerEdit.triggers
        let val = this.selectedContentForTriggerEdit.triggers[triggerName][triggerContent].split(',').map(Number)
        if(val.length > pos)
          val[pos] = content
        editedContent[triggerName][triggerContent] = val.join(",")
        this.selectedContentForTriggerEdit.triggers = editedContent
        this.dataHasChanged = true
        this.customTriggerWatchers(triggerName, triggerContent, content, false)
        this.$nextTick()
      },

      resetTriggers() {
        this.selectedContentForTriggerEdit = null
        this.selectedContentForTriggerEdit = JSON.parse(JSON.stringify(this.originalTriggerValues))
        this.originalTriggerValues = null
        this.dataHasChanged = false
        this.refreshScreens()
      },
      updateTrigger(triggerName, triggerContent, content, scfte) {
        // use passed through value if not null
        if(scfte)
          this.selectedContentForTriggerEdit = scfte
        // Make initial CLONED trigger
        if(this.originalTriggerValues === null)
          this.originalTriggerValues = JSON.parse(JSON.stringify(this.selectedContentForTriggerEdit))

        const editedContent = this.selectedContentForTriggerEdit.triggers
        editedContent[triggerName][triggerContent] = content
        this.selectedContentForTriggerEdit.triggers = editedContent

        // Loop through to see if any fields are different
        this.dataHasChanged = false
        // Compare 2 arrays by looping through each object + each object property and compare their value (2 level deep)
        Object.keys(this.originalTriggerValues.triggers).forEach(key => {
          Object.keys(this.originalTriggerValues.triggers[key]).forEach(k => {
            if(this.originalTriggerValues.triggers[key][k] !== this.selectedContentForTriggerEdit.triggers[key][k]) {
              this.dataHasChanged = true
            }
          })
        })
        this.customTriggerWatchers(triggerName, triggerContent, content, false)
      },
      async synchronizeLinkedContentTriggers() {
        this.copyLinkedTriggerDialog = false;
        try {
          if(this.selectedContentLinkedFormats) {
            var new_triggers = this.linkedContentForCopy.triggers;
            this.selectedContentLinkedFormats.forEach(async (x,i)=>{
              if(!x.campaignBurstScreenContent.isDeleted && 
                x.campaignBurstScreenContentId != this.linkedContentForCopy.id) {
                  var triggers = {};
                  for (const key in new_triggers) {
                    if(x.campaignBurstScreenContent.campaignBurstScreen.triggerTypes.some(t => t.name === key)) {
                      triggers[key] = new_triggers[key];
                    }
                  }
                  try {
                    let trigger = {
                      id: x.campaignBurstScreenContent.id,
                      paramPath: "json",
                      paramValue: JSON.stringify(triggers)
                    }
/*
                    await CampaignController.setTriggersOnContent(trigger)
                    .then(() => {
                      this.$root.$emit('snackbarSuccess','Trigger updated successfully!',i)
                      console.log(""+i+" Success");
                    })
                    .catch((err) => {
                      this.$root.$emit('snackbarError',''+err.response.data.message,)
                    })
                      */
                    try {
                      await CampaignController.setTriggersOnContent(trigger);
                      this.$root.$emit('snackbarSuccess', 'Trigger updated successfully!', i);
                    } catch (err) {
                      this.$root.$emit('snackbarError', '' + err.response.data.message);
                    }
                  } catch(err) {
                    console.log(err);
                    this.$root.$emit('snackbarError',''+err.response.data.message,)
                  }
                }
              /*
              if(!x.campaignBurstScreenContent.isDeleted && 
                x.campaignBurstScreenContentId != this.linkedContentForCopy.id) {
                for (const key in new_triggers) {
                  if (x?.campaignBurstScreenContent?.jTriggers?.[key] !== undefined) {
                    if(JSON.stringify(x.campaignBurstScreenContent.jTriggers[key]) != JSON.stringify(new_triggers[key])) {
                      for(const key2 in new_triggers[key]) {
                          if((""+x.campaignBurstScreenContent.jTriggers[key][key2]) != (""+new_triggers[key][key2])) {
                              console.log("key key2 ", key, key2
                                ,JSON.stringify(x.campaignBurstScreenContent.jTriggers[key][key2])
                                ,JSON.stringify(new_triggers[key][key2]));
                              let trigger = {
                                id: x.campaignBurstScreenContent.id,
                                paramPath: key+"."+key2,
                                paramValue: new_triggers[key][key2] 
                              }
                              try {
                                await CampaignController.setTriggerOnContent(trigger)
                                .then(() => {
                                  this.$root.$emit('snackbarSuccess','Trigger '+JSON.stringify(key2)+' updated successfully!',)
                                })
                                .catch((err) => {
                                  this.$root.$emit('snackbarError',''+err.response.data.message,)
                                })
                              } catch(err) {
                                console.log(err);
                                this.$root.$emit('snackbarError',''+err.response.data.message,)
                              }
                          }
                      }
                    }
                  } else if(x.campaignBurstScreenContent.campaignBurstScreen.triggerTypes.some(t => t.name === key)) {
                    for(const key2 in new_triggers[key]) {
                      console.log("Here > key key2 ", key, key2,
                        JSON.stringify(x?.campaignBurstScreenContent?.jTriggers), //old triggers
                        JSON.stringify(new_triggers[key][key2])); // new triggers
                        let trigger = {
                          id: x.campaignBurstScreenContent.id,
                          paramPath: key+"."+key2,
                          paramValue: new_triggers[key][key2] 
                        }
                        try {
                          await CampaignController.setTriggerOnContent(trigger)
                          .then(() => {
                            this.$root.$emit('snackbarSuccess','Trigger '+JSON.stringify(key2)+' updated successfully!',)
                          })
                          .catch((err) => {
                            this.$root.$emit('snackbarError',''+err.response.data.message,)
                          })
                        } catch(err) {
                          console.log(err);
                          this.$root.$emit('snackbarError',''+err.response.data.message,)
                        }
                    }
                  }
                }
              }
              /*****/
            });
          }
        } catch(err) {
          console.log("err"+err);
        }
      },
      async submitTriggers() {
        // if there is profanity and user didn't check that they checked show dialog
        if (this.selectedContentForTriggerEdit.triggers.uefaTriggers) {
          if (
            this.selectedContentForTriggerEdit.triggers.uefaTriggers.uefaProfanityChecks !== '' &&
            this.selectedContentForTriggerEdit.triggers.uefaTriggers.uefaProfanityConfirm == false &&
            this.selectedContentForTriggerEdit.triggers.uefaTriggers.uefaGrid == true
          )
            this.profanityDoubleCheckDialog = true
          else
            this.setTriggerQueue()
        } 
        else
          this.setTriggerQueue()
        this.sortScreenContents()

        // Check for Linked Content and ask to copy the triggers
        this.linkedContentForCopy = JSON.parse(JSON.stringify(this.selectedContentForTriggerEdit));
        var res = await CampaignController.getLinkedFormats(this.selectedContentForTriggerEdit.id);
        if(res?.data?.length > 0 && res.data[0]?.contentLinkGroup?.contentLinkGroupType?.shortName == "passivelink") {
          this.selectedContentLinkedFormats = res.data;
          this.copyLinkedTriggerDialog = true;
        }

        this.originalTriggerValues = null
        this.dataHasChanged = false
      },

      saveTriggerQueue(scfte) {
        const searchTriggersJson = (what) => this.triggerJson.triggers.find((element) => element.paramName === what)
        for (var x = 0; x < this.screenLevelTriggers.length; x++) {
          const screenLevelTrigger = this.screenLevelTriggers[x]
            if((Object.keys(scfte.triggers).includes(screenLevelTrigger))) {
              const propName = searchTriggersJson(screenLevelTrigger).parameters.map(x => x.paramName)
              const totalTriggers = propName.length
              for (var k = 0; k < totalTriggers; k++)
              {
                let paramPath = screenLevelTrigger + '.' + propName[k];
                let paramValue = scfte.triggers[screenLevelTrigger][propName[k]];
                this.pushTriggerToQueue(scfte,paramPath,paramValue)
              }
            }
        }
      },

      pushTriggerToQueue(scfte, paramPath, paramValue) {
        const triggerToSave = {
          id: scfte.id,
          paramPath: paramPath,
          paramValue: paramValue 
        }
        const triggerObj = {
          scfte: scfte,
          triggerToSave: triggerToSave
        }
        this.triggerSaveQueue.push(triggerObj)
      },

      async setTriggerQueue() {
        this.disableTriggerSave = true
        this.saveTriggerQueue(this.selectedContentForTriggerEdit)
        this.triggerBuilderDialog = false
        this.saveNextTrigger()
      },

      saveNextTrigger() {
        const nextTrigger = this.triggerSaveQueue.shift()
        let endSaveLog = null
        if(nextTrigger)
        {
          let triggerToSave = nextTrigger.triggerToSave
          let scfte = nextTrigger.scfte

          // check if we should save a log after this
          let lenLeft = this.triggerSaveQueue.filter(x => x.scfte.id === scfte.id).length
          if(lenLeft == 0)
            endSaveLog = scfte
          
          const triggerPath = triggerToSave.paramPath.split(".")
          CampaignController.setTriggerOnContent(triggerToSave)
            .then(() => {
              this.saveNextTrigger()
              if(this.triggerSaveQueue.length <= 1)
                this.$root.$emit('snackbarSuccess','Triggers updated successfully!',)
              else
                this.$root.$emit('snackbarSuccess','Trigger '+JSON.stringify(triggerPath[1])+' updated successfully!',)
            })
            .catch((err) => {
              this.saveNextTrigger()
              this.$root.$emit('snackbarError',''+err.response.data.message,)
            })
        }
        // save log if required
        if(endSaveLog != null)
        {
          this.saveTriggerLog(endSaveLog)
        }
        if(this.triggerSaveQueue.length == 0)
          this.selectedContentForTriggerEdit = null
      },

      saveTriggerLog(scfte) {
        CampaignController.logTriggerUpdate({
              id: scfte.id,
              action: 'UpdatedTriggers'
            }).then((res) => {
              res.data.triggers = JSON.parse(res.data.triggers)
              this.updateArtwork(res)
              this.disableTriggerSave = false
              // Reset store
              this.$store.dispatch('Clipboard/emptyClipboard')
            })
      },

      // Function to paste triggers to multiple artworks
      pasteGlobalTriggers() {
        // Assign list of selected artworks to paste into to a respective var 
        const selectedList = this.$store.state.Clipboard.selected
        const triggerSource = this.$store.state.Clipboard.triggersCopyData
        let sourceScfte = triggerSource;//this.screenContents.find(x => x.id === triggerSource.id)
        // Loader start
        this.$store.dispatch('Clipboard/toggleLoader', true)
        // For loop traversing the selected cards and assigning the triggers to respective artwork for update
        for(var i=0;i<selectedList.length;i++){
          let scfte = selectedList[i]
          // copy triggers from source to target
          scfte.triggers = JSON.parse(JSON.stringify(sourceScfte.triggers))
          this.saveTriggerQueue(scfte)
        }
        // Call recursive function to update db with updated triggers
        this.saveNextTrigger()
      },

      getReadableFileSizeString (fileSizeInBytes) {
        var i = -1
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
        do {
          fileSizeInBytes = fileSizeInBytes / 1024
          i++
        } while (fileSizeInBytes > 1024)
        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
      },
      // Function to check if the screenContent.media.fileSize is greater than this.selectedCampaignBurstScreen.screen.maxFileSizeKB
      isFileSizeBigger(fileSizeInBytes) {
        // convert bytes in kb
        fileSizeInBytes = fileSizeInBytes / 1024
        if(fileSizeInBytes > this.selectedCampaignBurstScreen.screen.maxFileSizeKB) {
          return true
        }
        return false
      },
      populateTriggerForm() {
        // Empty trigger form to avoid duplication bug.
        this.triggerForm = []
        // Loop through all the triggers on the screen Level and match them with the json doc
        for (var i = 0; i < this.screenLevelTriggers.length; i++) {
          // If trigger in json matches the screen level trigger, add vuetify components to each entry.
          for (var j = 0; j < this.triggerJson.triggers.length; j++) {
            if (
              this.triggerJson.triggers[j].paramName ===
              this.screenLevelTriggers[i]
            ) {
              for (
                var x = 0;
                x < this.triggerJson.triggers[j].parameters.length;
                x++
              ) {
                if (
                  this.triggerJson.triggers[j].parameters[x].type === 'boolean'
                ) {
                  this.triggerJson.triggers[j].parameters[x].component = 'v-checkbox'
                } else if (
                  this.triggerJson.triggers[j].parameters[x].type === 'cropper'
                ) {
                  this.triggerJson.triggers[j].parameters[x].component = 'FocalPoint'
                } else {
                  this.triggerJson.triggers[j].parameters[x].component = 'v-text-field'
                }
                if (
                  this.triggerJson.triggers[j].parameters[x].type === 'textarea'
                ) {
                  this.triggerJson.triggers[j].parameters[x].component = 'v-textarea'
                }
              }
              // After matching the json to the screen level triggers and adding the vuetify components
              if (this.triggerForm[this.triggerJson.triggers[j]] === undefined) {
                this.triggerForm.push(this.triggerJson.triggers[j])
              }
            }
          }
        }
      },

      populateScreenContentTriggers() {
        this.placeholderContentTrigger = new Object()
        // Loop through screen contents, if they have the screen level triggers leave it alone, else make object with defaultValues
        // Search the triggers json for screen level triggers
        const searchTriggersJson = (what) =>
          this.triggerJson.triggers.find((element) => element.paramName === what)
        for (var j = 0; j < this.screenLevelTriggers.length; j++) {
          this.placeholderContentTrigger[this.screenLevelTriggers[j]] = new Object()
          for (var k = 0; k < searchTriggersJson(this.screenLevelTriggers[j]).parameters.length; k++) 
              this.placeholderContentTrigger[this.screenLevelTriggers[j]][searchTriggersJson(this.screenLevelTriggers[j]).parameters[k].paramName] = searchTriggersJson(this.screenLevelTriggers[j]).parameters[k].defaultValue
        }
        this.placeholderContentTrigger = JSON.stringify(this.placeholderContentTrigger)
        this.addPlaceholderTriggersToContent()
      },

      addPlaceholderTriggersToContent() {
        // Loop through all content if it does not have the screen level triggers add the placeholderContentTrigger
        // Only loop through if there are screen level triggers else don't do nothing.

        if (this.screenLevelTriggers.length > 0) {
          for (var j = 0; j < this.screenLevelTriggers.length; j++) {
            for (var x = 0; x < this.screenContents.length; x++) {
              if (this.screenContents[x].triggers === {}) {
                this.screenContents[x].triggers = this.placeholderContentTrigger
              } else {
                if (typeof this.screenContents[x].triggers === 'string')
                  this.screenContents[x].triggers = JSON.parse(this.screenContents[x].triggers,)
                const searchTriggersJson = (what) => this.triggerJson.triggers.find((element) => element.paramName === what)
                // Check that all screen level triggers are there with their default vals..
                if (this.screenContents[x].triggers[this.screenLevelTriggers[j]] === undefined) 
                {
                  this.screenContents[x].triggers[this.screenLevelTriggers[j]] = {}
                  let trigger = searchTriggersJson(this.screenLevelTriggers[j])
                  for (var k = 0; k < trigger.parameters.length; k++)
                    this.screenContents[x].triggers[this.screenLevelTriggers[j]][searchTriggersJson(this.screenLevelTriggers[j],).parameters[k].paramName] = searchTriggersJson(this.screenLevelTriggers[j],).parameters[k].defaultValue
                }
              }
            }
          }
        }
      },

      fetchInternationalShowtimeMovies() {
        BespokeController.getInternationalShowtimeMovies()
            .then((res) => {
              this.internationalShowtimeMovies = res.data
            }).catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },

      fetchNationalRailStations() {
        BespokeController.getNationalRailStations()
            .then((res) => {
              this.nationalRailStations = res.data
            }).catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },

      fetchNationalRailOperators() {
        BespokeController.getNationalRailOperators()
            .then((res) => {
              this.nationalRailOperators = res.data
            }).catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },

      fetchIataAirports() {
        BespokeController.getIataAirports()
            .then((res) => {
              this.iataAirports = res.data
            }).catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },

      fetchAirports() {
        BespokeController.getAirports()
            .then((res) => {
              this.airports = res.data
            }).catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },

      fetchAirlines() {
        BespokeController.getAirlineOperators()
            .then((res) => {
              this.airlines = res.data
            }).catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },

      fetchRugbyTeams(competitionId, seasonId) {
        BespokeController.getRugbyTeams(competitionId, seasonId)
            .then((res) => {
              this.rugbyTeams = res.data
            }).catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },

      fetchLadbrokesInfo(mode, assignTo, expandMode, id) {
        if(!id)
          id = 0;
        if(!expandMode)
          expandMode = "";
        console.log("fetching ladbrokes info " + mode + " for " + id + " with expandMode " + expandMode + " setting " + assignTo);
        BespokeController.getLadbrokesInfo(mode, id, expandMode)
            .then((res) => {
              console.log("setting "+assignTo);
              this.$set(this.ladbrokesInfo, assignTo, res.data);
              this.$nextTick()
            }).catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },

      async downloadItem({ media }) {
        let downloadUrl
        let downloadFileName
        let mimeType
        downloadUrl = media.url
        downloadFileName = media.originalFileName
        mimeType = media.mimeType
        let cachebuster = Math.round(new Date().getTime() / 1000);
        const response = await axios.get(downloadUrl +"?rn="+cachebuster, { responseType: "blob" });
        const blob = new Blob([response.data], { type: mimeType });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = downloadFileName;
        link.click();
        URL.revokeObjectURL(link.href);
      },

      // helper function to determine if boolean or string value is true or false
      isTrue(value) {
        if (value === true || value === 'true') {
          return true
        } else {
          return false
        }
      },
      
      shouldShowOn(triggerShowOn, triggers, root) {

        let showsOn = [];
        let notShowsOn = [];

        const tokens = triggerShowOn.split(',');

        tokens.forEach(token => {
          const isNotCondition = token.startsWith('!');
          const cleanedToken = isNotCondition ? token.slice(1) : token;
          var [key, value] = cleanedToken.split('[');
          value = value ? value.slice(0, -1) : null; // remove ] from value
          if(isNotCondition)
            notShowsOn.push([key, value]);
          else
            showsOn.push([key, value]);
        });

        // if both lists are empty, shows on is *
        if(showsOn.length === 0 && notShowsOn.length === 0)
          showsOn.push(['*', null]);

        // does triggers have the key. If the r[1] is null it's true, if r[1] is not null, it must match the value
          // key == * is always true
          // notShowOn is all the opposite
        return (showsOn.some((r) => { 
          var val = triggers[root] ? triggers[root][r[0]] : triggers[r[0]]; // triggers[r[0]] is legacy for "instagram" and "tweet"      
          return (r[0] === '*' || (val && (r[1] === null || val === r[1])))
        }))
        && !(notShowsOn.some((r) => { 
          var val = triggers[root] ? triggers[root][r[0]] : triggers[r[0]]; // triggers[r[0]] is legacy for "instagram" and "tweet"      
          return (r[0] === '*' || (val && (r[1] === null || val === r[1])))
        }))

      },

    },

    
  }
</script>
<!-- Style that targets only the edit triggers dialog and align it to the top. rather then center (default)-->
<style scoped>
  .v-dialog__content:has(.editDialogCustom) {
    align-items: start;
  }
  /* this style is applied when the card is clicked */
.flipme {
  transform: rotateY(180deg);
}
.card {
  
  transition: transform 0.7s;
  transform-style: preserve-3d;
}
.card__face {
  align-items: start;
  backface-visibility: hidden;
}
.card__face--back {
  transform: rotateY(180deg);
}
</style>
<!-- End Style-->
<style scoped lang="scss">
  .sequence-group {
    &:hover {
      cursor: pointer;
    }
  }

  .twitter-text {
    text-decoration: none;
    color:#eee;
    &:visited {
      color: inherit;
    }
  }

  .twitter-date {
    margin-top: 7px;
    text-decoration: none;
    color:rgb(150, 150, 150);
    &:visited {
      color: inherit;
    }
  }

  .fab-icon {
    padding: 1px;
    font-size: 14px !important;
    background: rgb(180, 180, 180);
    border-radius: 50%;
    
    &:hover {
      background: rgb(244, 67, 54);
    }
  }
</style>

<style scoped>
.highlightCard {
  background-color: #5f5f5f;
}
.color-picker {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;


}

.color-btn {
  width: 10px !important; 
  padding: 1px 0px !important;
  
}
.selected-color {
  border: 2px solid #3498db; 
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5); 
}
/* Dark theme styling */
.daterangepicker {
    font-family: 'Roboto', sans-serif !important;
  }

  .vue-daterange-picker[data-v-1ebd09d2] {
    width: 100% !important;
  }
</style>



